import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const register = createAsyncThunk(
  "auth/registerUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/auth/register`,
        userData
      );
      toast.success("Registration successful!");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message || "Registration failed.");
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getReferralCode = createAsyncThunk(
  "auth/getReferralCode",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/users/getReferralCode`
      );
      // toast.success("Referal code generated sucessfully!");
      return response.data;
    } catch (error) {
      toast.error(
        error.response.data.message || "Failed to generate referal code."
      );
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/login`, userData);
      toast.success("Logged in successfully!");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message || "Login failed.");
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const sendOtp = createAsyncThunk(
  "auth/sendOtp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/v1/auth/send-otp`, {
        phoneNumber: data,
      });
      toast.success("Otp sent successful!");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message || "Failed to send otp.");
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/auth/verify-otp`,
        data
      );
      // toast.success("Otp verified successfully!");
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message || "Failed to send otp.");
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const logoutThunk = createAsyncThunk(
  "auth/logoutThunk",
  async (_, { rejectWithValue }) => {
    try {
      console.log("inside Slice");

      const response = await axios.post(`${BASE_URL}/api/v1/auth/logout`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const employeeLogoutThunk = createAsyncThunk(
  "auth/employeeLogoutThunk",
  async (_, { rejectWithValue }) => {
    try {
      console.log("inside Slice");
      const response = await axios.post(
        `${BASE_URL}/api/v1/employee/employeeLogout`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    user: null,
    status: "idle",
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload.data;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(sendOtp.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.user = action.payload.data;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(verifyOtp.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.user = action.payload.data;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(register.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(register.fulfilled, (state, action) => {
        state.status = "Sucess";
        state.user = action.payload.data;
      })
      .addCase(register.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(getReferralCode.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getReferralCode.fulfilled, (state, action) => {
        state.status = "Sucess";
        state.user = action.payload.data;
      })
      .addCase(getReferralCode.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(logoutThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(logoutThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "Sucess";
      })
      .addCase(logoutThunk.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(employeeLogoutThunk.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(employeeLogoutThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "Sucess";
      })
      .addCase(employeeLogoutThunk.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.payload;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
