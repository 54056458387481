import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getUserThunk = createAsyncThunk(
  "user/getCustomerThunk",
  async ({ page, limit, sortingColumn, sortingOrder, user_type }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v1/users/getUserDetail?page=${page}&limit=${limit}&sortingColumn=${sortingColumn}&sortingOrder=${sortingOrder}&user_type=${user_type}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const downloadUserDataThunk = createAsyncThunk(
  "user/downloadUserDataThunk",
  async ({ user_type }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v1/users/downloadUserData?user_type=${user_type}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getBookingHistory = createAsyncThunk(
  "user/getBookingHistory",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v1/plots/bookingHistory?page=1&limit=3&user_id=${userId}&sortingColumn=createdAt&sortingOrder=desc`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
    loading: false,
    error: null,
    selectedUser: null,
    bookingHistory: [],
    paymentHistory: [],
    totalUsers: 0,
    downloadedUserData: null,
  },
  reducers: {
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },
    resetHistory: (state) => {
      state.bookingHistory = [];
      state.paymentHistory = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.users;
        state.totalUsers = action.payload.totalUsers;
      })
      .addCase(getUserThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(downloadUserDataThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadUserDataThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.downloadedUserData = action.payload.data;
      })
      .addCase(downloadUserDataThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBookingHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBookingHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingHistory = action.payload.data;
        state.paymentHistory = action.payload.data;
      })
      .addCase(getBookingHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setSelectedUser, resetHistory } = userSlice.actions;
const userReducer = userSlice.reducer;
export default userReducer;
