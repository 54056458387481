import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import "./App.css";
import Loader from "./components/loader/Loader";
import ThemeSelector from "./layouts/theme/ThemeSelector";
import ThemeRoutes from "./routes/router";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/ErrorFallback";

function App() {
  const routing = useRoutes(ThemeRoutes);
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Reset application state or perform cleanup here
        window.location.reload();
      }}
    >
      <Suspense fallback={<Loader />}>
        <div
          className={`${direction ? "rtl" : "ltr"} ${isMode ? "dark" : ""}`}
          dir={direction ? "rtl" : "ltr"}
        >
          <ThemeSelector />
          {routing}
        </div>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
