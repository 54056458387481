import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getPaymentHistoryThunk = createAsyncThunk(
  "payment/getPaymentHistoryThunk",
  async ({page, limit, sortingColumn, sortingOrder,payment_status, search},  { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/users/paymentHistory?page=${page}&limit=${limit}&sortingColumn=${sortingColumn}&payment_status=${payment_status}&sortingOrder=${sortingOrder}&search=${search}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    loading: false,
    error: null,
    payments: [],
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentHistoryThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPaymentHistoryThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.payments = action.payload.data.payments;
        state.totalCount = action.payload.totalCount
      })
      .addCase(getPaymentHistoryThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const paymentReducer = paymentSlice.reducer;
export default paymentReducer;
