import Axios from "./axios";

export const sendOtp = (data) => {
  return Axios.post("/api/v1/auth/send-otp", data);
};

export const verifyOtp = (data) => {
  return Axios.post("/api/v1/auth/verify-otp", data);
};

export const register = (data) => {
  return Axios.post("/api/v1/auth/register", data);
};

export const getReferralCode = () => {
  return Axios.get("/api/v1/users/getReferralCode");
};

export const createPlot = (data) => {
  return Axios.post("/api/v1/plots/createPlot", data);
};

export const getMainPlot = () => {
  return Axios.get("/api/v1/plots/getMainPlot");
};

export const getSuperAdminDashboardData = () => {
  return Axios.get("/api/v1/plots/dashboard-details");
};

export const deletePlot = (data) => {
  return Axios.delete("/api/v1/admin/deletePlot", {
    params: data,
  });
};

export const updatePlot = (data) => {
  return Axios.put("/api/v1/admin/updatePlot", data);
};

export const getBookingDataById = (data) => {
  return Axios.get("/api/v1/plots/bookingHistory", { params: data });
};

export const getDocumentDataById = () => {
  return Axios.get("/api/v1/users/getDocumentDataById");
};

export const getNotificationByUserId = () => {
  return Axios.get("/api/v1/notification/getNotificationByUserId");
};

export const updateAllReadNotification = () => {
  return Axios.post("/api/v1/notification/updateAllReadNotification");
};

export const getAllPaymentHistory = (data) => {
  return Axios.get("/api/v1/users/getAllPaymentHistory", { params: data });
};

export const totalPlotsSold = () => {
  return Axios.get("/api/agents/dashboard/totalPlotsSold");
};

export const leadsCountData = () => {
  return Axios.get("/api/agents/dashboard/leadsCountData");
};

export const comissionData = () => {
  return Axios.get("/api/agents/dashboard/comissionData");
};

export const downlinePerformance = () => {
  return Axios.get("/api/agents/downline-performance");
};

export const getCommissions = () => {
  return Axios.get("/api/agents/commissions");
};

export const getAgentTask = () => {
  return Axios.get("/api/v1/task/get_agent_task");
};

export const getProfileData = () => {
  return Axios.get("/api/v1/users/getProfileData");
};

export const salePipeline = (data) => {
  return Axios.get("/api/v1/plots/salePipeline", { params: data });
};

export const salesPlotGraph = (data) => {
  return Axios.get("/api/v1/plots/salesPlotGraph", { params: data });
};

export const agentPerformance = (data) => {
  return Axios.get("/api/v1/plots/agentPerformance", { params: data });
};

export const commissionData = (data) => {
  return Axios.get("/api/v1/plots/commissionData", { params: data });
};

export const agentWallet = (data) => {
  return Axios.get("/api/v1/users/agentWallet", { params: data });
};

export const getApprovalData = (data) => {
  return Axios.get("/api/v1/plots/getApprovalData", { params: data });
};

export const getAllEmployeesDayWiseAttendance = (data) => {
  return Axios.get("/api/v1/attendance/getAllEmployeesDayWiseAttendance", {
    params: data,
  });
};

export const searchEmployeeList = () => {
  return Axios.get("/api/v1/employee/searchEmployeeList");
};

export const createDayAttendance = (data) => {
  return Axios.post("/api/v1/attendance/createDayAttendance", data);
};

export const updateDayAttendance = (data) => {
  return Axios.post("/api/v1/attendance/updateDayAttendance", data);
};

export const deleteDayAttendance = (data) => {
  return Axios.delete("/api/v1/attendance/deleteDayAttendance", {
    params: data,
  });
};

export const getAttendanceData = (data) => {
  return Axios.get("/api/v1/attendance/getAttendanceData", {
    params: data,
  });
};

export const getPayrollData = (data) => {
  return Axios.get("/api/v1/attendance/getPayrollData", {
    params: data,
  });
};

export const updatePayrollData = (data) => {
  return Axios.post("/api/v1/attendance/updatePayrollData", data);
};

export const createPayrollData = (data) => {
  return Axios.post("/api/v1/attendance/createPayrollData", data);
};

export const getOneAttendanceData = (data) => {
  return Axios.get("/api/v1/attendance/getOneAttendanceData", {
    params: data,
  });
};

export const getMessage = (data) => {
  return Axios.get("/api/v1/employee/getMessage", {
    params: data,
  });
};
