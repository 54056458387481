import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getBannerThunk = createAsyncThunk(
  "banner/getBannerThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/api/v1/banner/banner");
      console.log(response.data, "this is a response")
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createBannerThunk = createAsyncThunk(
  "banner/createBannerThunk",
  async (bannerData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/api/v1/banner/banner/create",
        bannerData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateBannerThunk = createAsyncThunk(
    "banner/updateBannerThunk",
    async ({ id, data }, { rejectWithValue }) => {
      try {
        const response = await axios.put(`/api/v1/banner/banner/${id}`, data);
        return response.data; 
      } catch (error) {
        return rejectWithValue(error.response.data.message);
      }
    }
  );

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    banners: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBannerThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBannerThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.banners = action.payload.banners;
      })
      .addCase(getBannerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createBannerThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBannerThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.banners?.push(action.payload.data);
      })
      .addCase(createBannerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateBannerThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBannerThunk.fulfilled, (state, action) => {
        state.loading = false;
        const updatedBanner = action.payload;
        state.banners = state.banners.map(banner =>
          banner._id === updatedBanner._id ? updatedBanner : banner
        );
      })
      .addCase(updateBannerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const bannerReducer = bannerSlice.reducer;
export default bannerReducer;
