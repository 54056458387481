import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice.js";
import customizerReducer from "../features/CustomizerSlice.js";
import agentReducer from "../features/agentSlice.js";
import plotReducer from "../features/plotSlice.js";
import adminReducer from "../features/adminSlice.js";
import bookingReducer from "../features/Customer/bookingSlice.js";
import paymentReducer from "../features/Customer/paymentSlice.js";
import profileReducer from "../features/Customer/profileSlice.js";
import employeeReducer from "../features/employeeSlice.js";
import userReducer from "../features/SuperAdmin/userManageSlice.js";
import documentReducer from "../features/SuperAdmin/documentSlice.js";
import bannerReducer from "../features/SuperAdmin/bannerSlice.js";
import payoutReducer from "../features/Agent/payoutSlice.js";
import superAdminBookingReducer from "../features/SuperAdmin/allBookingSlice.js";
import crmReducer from "../features/SuperAdmin/crmSlice.js";
import ChatsReducer from "../features/chatSlice.js";
import reportReducer from "../features/SuperAdmin/reportSlice.js";
import approveReducer from "../features/employee/approveSlice.js";
import couponReducer from "../features/SuperAdmin/couponSlice.js";

const rootReducer = combineReducers({
  auth: authReducer,
  customizer: customizerReducer,
  agent: agentReducer,
  plot: plotReducer,
  admin: adminReducer,
  booking: bookingReducer,
  payment: paymentReducer,
  profile: profileReducer,
  employee: employeeReducer,
  user: userReducer,
  booking: superAdminBookingReducer,
  document: documentReducer,
  banner: bannerReducer,
  coupon: couponReducer,
  payout: payoutReducer,
  crm: crmReducer,
  report: reportReducer,
  approve: approveReducer,
  chatReducer: ChatsReducer,
  report: reportReducer
});

export const store = configureStore({
  reducer: rootReducer,
});
