import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserSidebar from "./UserSidebar";
import EmployeeSidebar from "./EmployeeSidebar";
import { decodeJwtToken } from "../../../utils/decodeJwt";

const Sidebar = () => {
  const navigate = useNavigate();

  // Fetch token
  const token =
    localStorage.getItem("token") || localStorage.getItem("employeeToken");
  const decodedToken = decodeJwtToken(token);
  const isEmployee = localStorage.getItem("employeeToken");

  console.log(decodedToken);
  

  useEffect(() => {
    if (!decodedToken) {
      // Redirect to login if token is invalid
      return navigate("/login");
    }

    if (isEmployee) {
      // Employee Redirection based on Permissions
      // const permissions = decodedToken.permissions || [];
      // if (permissions.includes("VIEW_DASHBOARD")) {
      return navigate("/employee/dashboard");
      // } else {
      //   return navigate("/employee-login"); // Default or restricted access page
      // }
    } else {
      // User Redirection based on userType
      const userType = decodedToken.userType;
      console.log(userType);
      switch (userType) {
        case "agent":
          return navigate("/agent/dashboard");
        case "customer":
          return navigate("/customer/dashboard");
        default:
          return navigate("/user/no-access"); // Default or restricted access page for users
      }
    }
  }, [isEmployee]);

  // Render the appropriate sidebar
  return isEmployee ? (
    <EmployeeSidebar permissions={decodedToken.permissions} />
  ) : (
    <UserSidebar userType={decodedToken.userType} />
  );
};

export default Sidebar;
