import React, { useEffect } from "react";
import { Nav } from "reactstrap";
import SimpleBar from "simplebar-react";
import NavItemContainer from "./NavItemContainer";
import NavSubMenu from "./NavSubMenu";
import EmployeeSidebarData from "../sidebardata/EmployeeSidebarData";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/logos/logo.png";

const EmployeeSidebar = ({ permissions }) => {
  // Filter data based on permissions

  const location = useLocation();
  const currentURL = location.pathname.split("/").slice(0, -1).join("/");

  const activeBg = useSelector((state) => state.customizer.sidebarBg);
  const isFixed = useSelector((state) => state.customizer.isSidebarFixed);
  const dispatch = useDispatch();

  const allowedPermissions = Array.isArray(permissions)
    ? permissions
    : [permissions];

  const filterSidebarData = (sidebarData, permissions) => {
    return sidebarData
      .map((item) => {
        if (item.collapisble && item.children) {
          // Recursively filter the children
          const filteredChildren = filterSidebarData(
            item.children,
            permissions
          );

          // Include the parent only if any child is allowed
          if (filteredChildren.length > 0) {
            return {
              ...item,
              children: filteredChildren,
            };
          }
          return null; // Exclude parent if no children are allowed
        } else {
          // Non-collapsible items: check permissions directly
          return permissions.includes(item.requiredPermissions) ? item : null;
        }
      })
      .filter((item) => item !== null); // Remove null entries
  };

  // Filter the data
  const filteredSidebarData = filterSidebarData(
    EmployeeSidebarData,
    allowedPermissions
  );

  useEffect(() => {}, [dispatch, filteredSidebarData]);

  return (
    <div
      className={`sidebarBox shadow bg-${activeBg} ${
        isFixed ? "fixedSidebar" : ""
      }`}
    >
      <SimpleBar style={{ height: "100%" }}>
        <div className="d-flex p-3 align-items-center justify-center">
          <img src={Logo} width={130} height={35} />
        </div>
        {/* Sidebar Content */}
        <Nav vertical className={activeBg === "white" ? "" : "lightText"}>
          {filteredSidebarData.map((navi) => {
            if (navi.caption) {
              return (
                <div
                  className="navCaption fw-bold text-uppercase mt-4"
                  key={navi.caption}
                >
                  {navi.caption}
                </div>
              );
            }
            if (navi.children) {
              return (
                <NavSubMenu
                  key={navi.id}
                  icon={navi.icon}
                  title={navi.title}
                  items={navi.children}
                  suffix={navi.suffix}
                  suffixColor={navi.suffixColor}
                  isUrl={currentURL === navi.href}
                  className="text-base"
                />
              );
            }
            return (
              <NavItemContainer
                key={navi.id}
                className={location.pathname === navi.href ? "activeLink" : ""}
                to={navi.href}
                title={navi.title}
                suffix={navi.suffix}
                suffixColor={navi.suffixColor}
                icon={navi.icon}
              />
            );
          })}
        </Nav>
      </SimpleBar>
    </div>
  );
};

export default EmployeeSidebar;
