import "react-datetime/css/react-datetime.css";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import Customizer from "./customizer/Customizer";
import Header from "./header/Header";
import HorizontalHeader from "./header/HorizontalHeader";
import socketIOClient from "socket.io-client";
import { useContext, useEffect } from "react";
import { ChatContext } from "../context";
import { decodeToken } from "../utils/decodeJwt";
import { BASE_URL } from "../utils/constant";
import Sidebar from "./sidebars/vertical/Sidebar";

const FullLayout = () => {
  const customizerToggle = useSelector(
    (state) => state.customizer.customizerSidebar
  );
  const toggleMiniSidebar = useSelector(
    (state) => state.customizer.isMiniSidebar
  );
  const showMobileSidebar = useSelector(
    (state) => state.customizer.isMobileSidebar
  );
  const topbarFixed = useSelector((state) => state.customizer.isTopbarFixed);
  const LayoutHorizontal = useSelector(
    (state) => state.customizer.isLayoutHorizontal
  );
  const isFixedSidebar = useSelector(
    (state) => state.customizer.isSidebarFixed
  );

  const token =
    localStorage.getItem("token") || localStorage.getItem("employeeToken");

  const { setSocket, socket } = useContext(ChatContext);

  const socketConnection = (token) => {
    if (!socket) {
      const soc = socketIOClient(BASE_URL, {
        query: `token=${token}`,
        transports: ["websocket"],
        forceNew: true,
      });
      setSocket(soc);
    }
  };

  useEffect(() => {
    if (token) {
      socketConnection(token);
    }
    return () => {
      if (socket) {
        socket.removeAllListeners();
        socket.disconnect();
      }
      setTimeout(() => {
        setSocket(null);
      }, 1000);
    };
  }, [token]);

  return (
    <main>
      <div
        className={`pageWrapper d-md-block d-lg-flex ${
          toggleMiniSidebar ? "isMiniSidebar" : ""
        }`}
      >
        {/******** Sidebar **********/}
        {LayoutHorizontal ? (
          ""
        ) : (
          <aside
            className={`sidebarArea ${showMobileSidebar ? "showSidebar" : ""}`}
          >
            <Sidebar />
          </aside>
        )}
        {/********Content Area**********/}

        <div className={`contentArea ${topbarFixed ? "fixedTopbar" : ""}`}>
          {/********header**********/}
          {LayoutHorizontal ? <HorizontalHeader /> : <Header />}
          {/* {LayoutHorizontal ? <HorizontalSidebar /> : ''} */}
          {/********Middle Content**********/}
          {/* <div
            className={
              isFixedSidebar && LayoutHorizontal ? "HsidebarFixed" : ""
            }
          >
            <div className="bg-white p-3 text-capitalize">
              <Container fluid className="boxContainer">
                <h5
                  className="fw-medium mb-
                0"
                >
                  Agent Dashboard
                </h5>
              </Container>
            </div>
          </div> */}

          <Container fluid className="p-6 boxContainer ">
            <div>
              <Outlet />
            </div>
            <Customizer className={customizerToggle ? "showCustomizer" : ""} />
            {showMobileSidebar || customizerToggle ? (
              <div className="sidebarOverlay" />
            ) : (
              ""
            )}
          </Container>
        </div>
      </div>
    </main>
  );
};

export default FullLayout;
