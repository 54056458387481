import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

const token = localStorage.getItem("token");

export const getDownlinePerformanceThunk = createAsyncThunk(
  "agent/get-downline-performance",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/agents/downline-performance`
      );
      toast.success("All downline agent performance is sucessfully fetched!");
      return response.data;
    } catch (error) {
      toast.error(
        error.response.data.message || "Failed to fetch downline performance!"
      );
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getCommissionDataThunk = createAsyncThunk(
  "agent/getCommissionDataThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/agents/commissions`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const requestPayoutThunk = createAsyncThunk(
  "agent/requestPayoutThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/agents/request-comission-payout`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAgentCustomers = createAsyncThunk(
  "agent/getAgentCustomers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/agents/get_all_customers`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send token in Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addNewCustomersThunk = createAsyncThunk(
  "agent/addNewCustomersThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/agents/add_new_customers`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send token in Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getLeadsThunk = createAsyncThunk(
  "agent/getLeadsThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/leads/get-leads`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createLeadsThunk = createAsyncThunk(
  "agent/createLeadsThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/leads/create-leads`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getDownlineAgentsThunk = createAsyncThunk(
  "agent/getDownlineAgentsThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/agents/get_agents`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const assignLeadThunk = createAsyncThunk(
  "leads/assignLeadThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/leads/assign-leads/${data.leadId}/assign`,
        {
          agentId: data.agentId,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateLeadsThunk = createAsyncThunk(
  "leads/updateLeadsThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/leads/update-leads/${data.leadId}/status`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const assignTaskToAgentThunk = createAsyncThunk(
  "task/assignTaskToAgentThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/task/assign_task`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAgentTasksThunk = createAsyncThunk(
  "task/getAgentTasksThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/task/get_agent_task`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateTaskStatusThunk = createAsyncThunk(
  "task/updateTaskThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/task/update_task_status`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const totalPlotsSoldThunk = createAsyncThunk(
  "dashboard/totalPlotsSoldThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/agents/dashboard/totalPlotsSold`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getLeadStatusCountThunk = createAsyncThunk(
  "dashboard/getLeadStatusCountThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/agents/dashboard/leadsCountData`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getDashboardCommissionThunk = createAsyncThunk(
  "dashboard/getDashboardCommissionThunk",
  async (comissionRange, { rejectWithValue }) => {
    console.log(comissionRange);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/agents/dashboard/comissionData`,
        { filterType: comissionRange }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getRecentTaskThunk = createAsyncThunk(
  "dashboard/getRecentTaskThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/agents/dashboard/recentTask`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getRecentInteractionThunk = createAsyncThunk(
  "dashboard/getRecentInteractionThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/agents/dashboard/recentInteraction`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getRecentBookedPlotsThunk = createAsyncThunk(
  "dashboard/getRecentBookedPlotsThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/agents/dashboard/recentBookedPlots`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getRecentSalesThunk = createAsyncThunk(
  "dashboard/getRecentSalesThunk",
  async (filterType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/agents/dashboard/recentSales`,
        { filterType: filterType }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const agentSlice = createSlice({
  name: "agent",
  initialState: {
    loading: false,
    agents: null,
    status: "IDLE",
    error: null,
    commissions: null,
    data: {
      agents: null,
      commissions: null,
      customers: null,
      leads: null,
      tasks: [],
      // dashboard
      totalPlotsSold: null,
      leadsCount: null,
      commissionData: null,
      recentTask: null,
      recentInteraction: null,
      recentBookedPlots: null,
      recentSalesData: null,
    },
  },
  reducers: {
    // logout: (state) => {
    //   state.user = null;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDownlinePerformanceThunk.pending, (state, action) => {
        state.status = "LOADING";
      })
      .addCase(getDownlinePerformanceThunk.fulfilled, (state, action) => {
        state.status = "SUCCESS";
        state.data.agents = action.payload.data;
      })
      .addCase(getDownlinePerformanceThunk.rejected, (state, action) => {
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getCommissionDataThunk.pending, (state, action) => {
        state.status = "LOADING";
      })
      .addCase(getCommissionDataThunk.fulfilled, (state, action) => {
        state.status = "SUCCESS";
        state.data.commissions = action.payload.data;
      })
      .addCase(getCommissionDataThunk.rejected, (state, action) => {
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(requestPayoutThunk.pending, (state, action) => {
        state.status = "LOADING";
      })
      .addCase(requestPayoutThunk.fulfilled, (state, action) => {
        state.status = "SUCCESS";
      })
      .addCase(requestPayoutThunk.rejected, (state, action) => {
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getAgentCustomers.pending, (state, action) => {
        state.status = "LOADING";
      })
      .addCase(getAgentCustomers.fulfilled, (state, action) => {
        state.status = "SUCCESS";
        state.data.customers = action.payload.data;
      })
      .addCase(getAgentCustomers.rejected, (state, action) => {
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(addNewCustomersThunk.pending, (state, action) => {
        state.status = "LOADING";
      })
      .addCase(addNewCustomersThunk.fulfilled, (state, action) => {
        state.status = "SUCCESS";
      })
      .addCase(addNewCustomersThunk.rejected, (state, action) => {
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getLeadsThunk.pending, (state, action) => {
        state.status = "LOADING";
      })
      .addCase(getLeadsThunk.fulfilled, (state, action) => {
        state.status = "SUCCESS";
        state.data.leads = action.payload.data;
      })
      .addCase(getLeadsThunk.rejected, (state, action) => {
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(createLeadsThunk.pending, (state, action) => {
        state.status = "LOADING";
      })
      .addCase(createLeadsThunk.fulfilled, (state, action) => {
        state.status = "SUCCESS";
      })
      .addCase(createLeadsThunk.rejected, (state, action) => {
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getDownlineAgentsThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getDownlineAgentsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
        state.data.agents = action.payload.data;
      })
      .addCase(getDownlineAgentsThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(assignLeadThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(assignLeadThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
      })
      .addCase(assignLeadThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(updateLeadsThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(updateLeadsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
      })
      .addCase(updateLeadsThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(assignTaskToAgentThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(assignTaskToAgentThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
      })
      .addCase(assignTaskToAgentThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getAgentTasksThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getAgentTasksThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
        state.data.tasks = action.payload.data;
      })
      .addCase(getAgentTasksThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(updateTaskStatusThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(updateTaskStatusThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
      })
      .addCase(updateTaskStatusThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(totalPlotsSoldThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(totalPlotsSoldThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data.totalPlotsSold = action.payload.data;
        state.status = "SUCCESS";
      })
      .addCase(totalPlotsSoldThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getLeadStatusCountThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getLeadStatusCountThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data.leadsCount = action.payload.data;
        state.status = "SUCCESS";
      })
      .addCase(getLeadStatusCountThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getDashboardCommissionThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getDashboardCommissionThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data.commissionData = action.payload.data;
        state.status = "SUCCESS";
      })
      .addCase(getDashboardCommissionThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getRecentTaskThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getRecentTaskThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data.recentTask = action.payload.data;
        state.status = "SUCCESS";
      })
      .addCase(getRecentTaskThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getRecentInteractionThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getRecentInteractionThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data.recentInteraction = action.payload.data;
        state.status = "SUCCESS";
      })
      .addCase(getRecentInteractionThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getRecentBookedPlotsThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getRecentBookedPlotsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data.recentBookedPlots = action.payload.data;
        state.status = "SUCCESS";
      })
      .addCase(getRecentBookedPlotsThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getRecentSalesThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getRecentSalesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data.recentSalesData = action.payload.data;
        state.status = "SUCCESS";
      })
      .addCase(getRecentSalesThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      });
  },
});

export default agentSlice.reducer;
