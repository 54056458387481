import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getCouponThunk = createAsyncThunk(
  "coupon/getCouponThunk",
  async ({ page, limit, sortingOrder, sortingColumn }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v1/coupon/getAllCoupon?page=${page}&limit=${limit}&sortingOrder=${sortingOrder}&sortingColumn=${sortingColumn}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createCouponThunk = createAsyncThunk(
  "coupon/createCouponThunk",
  async (couponData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/api/v1/coupon/generateCoupon",
        couponData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const assignCouponThunk = createAsyncThunk(
  "coupon/createCouponThunk",
  async ({ agentId, couponId }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/v1/coupon/coupons/assign", {
        agentId,
        couponId,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateCouponThunk = createAsyncThunk(
  "coupon/updateCouponThunk",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/v1/banner/banner/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    coupons: [],
    loading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCouponThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCouponThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.coupons = action.payload.coupons;
        state.totalCount = action.payload.totalCoupons;
      })
      .addCase(getCouponThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createCouponThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCouponThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.coupons?.push(action.payload.data);
      })
      .addCase(createCouponThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const couponReducer = couponSlice.reducer;
export default couponReducer;
