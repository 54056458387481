import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem("token") || localStorage.getItem("employeeToken");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getAllPayoutDataThunk = createAsyncThunk(
  "payout/getAllPayoutDataThunk",
  async (
    { page, limit, sortingColumn, sortingOrder, status, search },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get(
        `/api/v1/payout/getAllPayoutData?page=1&limit=3&sortingColumn=createdAt&sortingOrder=asc&status=${status}`
      );
      console.log(response.data, "this is a response");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createPayoutByAgentThunk = createAsyncThunk(
  "payout/createPayoutByAgentThunk",
  async ({ amount }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/v1/payout/createPayoutByAgent", {
        amount,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateStatusPayoutThunk = createAsyncThunk(
  "payout/updateStatusPayoutThunk",
  async ({ _id, status, reason }, { rejectWithValue }) => {
    try {
      const response = await axios.put("/api/v1/payout/updateStatusPayout", {
        _id,
        status,
        reason,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const payoutSlice = createSlice({
  name: "payout",
  initialState: {
    payouts: [],
    loading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPayoutDataThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllPayoutDataThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.payouts = action.payload.data;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(getAllPayoutDataThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(createPayoutByAgentThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPayoutByAgentThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.payouts.push(action.payload.data);
      })
      .addCase(createPayoutByAgentThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(updateStatusPayoutThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateStatusPayoutThunk.fulfilled, (state, action) => {
        state.loading = false;
        const updatedPayout = action.payload;
        state.payouts = state.payouts.map((payout) =>
          payout._id === updatedPayout._id ? updatedPayout : payout
        );
      })
      .addCase(updateStatusPayoutThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

const payoutReducer = payoutSlice.reducer;
export default payoutReducer;
