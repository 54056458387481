import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap";
import user1 from "../../assets/images/users/user1.jpg";
import user2 from "../../assets/images/users/user2.jpg";
import user3 from "../../assets/images/users/user3.jpg";
import user4 from "../../assets/images/users/user4.jpg";
import user5 from "../../assets/images/users/user5.jpg";

const MessageDD = ({ messages }) => {
  console.log(messages);
  return (
    <div>
      <ListGroup flush>
        {messages &&
          messages.map((msg) => (
            <ListGroupItem action key={msg._id} tag="a" href="/">
              <div className="d-flex align-items-center gap-3 py-2">
                <img
                  src={user1}
                  alt="user"
                  className="rounded-circle flex-shrink-0"
                  width="50"
                />
                <div className="col-9">
                  <ListGroupItemHeading className="fw-medium mb-0">
                    {msg.notification_type}
                  </ListGroupItemHeading>
                  <ListGroupItemText className="text-muted text-truncate mb-0 d-block">
                    {msg.message}
                  </ListGroupItemText>
                  <small className="text-muted">{msg.createdAt}</small>
                </div>
              </div>
            </ListGroupItem>
          ))}
      </ListGroup>
    </div>
  );
};

export default MessageDD;
