import React, { useEffect } from "react";
import { Nav } from "reactstrap";
import SimpleBar from "simplebar-react";
import NavItemContainer from "./NavItemContainer";
import NavSubMenu from "./NavSubMenu";
import UserSidebarData from "../sidebardata/UserSidebarData";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../assets/images/logos/logo.png";

const UserSidebar = ({ userType }) => {
  const location = useLocation();
  const currentURL = location.pathname.split("/").slice(0, -1).join("/");

  const activeBg = useSelector((state) => state.customizer.sidebarBg);
  const isFixed = useSelector((state) => state.customizer.isSidebarFixed);
  const dispatch = useDispatch();

  // Filter data based on userType
  const filteredSidebarData = UserSidebarData.filter((item) =>
    item.requiredUserType.includes(userType)
  );

  // useEffect(() => {}, [dispatch, filteredSidebarData]);

  return (
    <div
      className={`sidebarBox shadow bg-${activeBg} ${
        isFixed ? "fixedSidebar" : ""
      }`}
    >
      <SimpleBar style={{ height: "100%" }}>
        <div className="d-flex p-3 align-items-center justify-center">
          <img src={Logo} width={130} height={35} />
        </div>
        {/* Sidebar Content */}
        <Nav vertical className={activeBg === "white" ? "" : "lightText"}>
          {filteredSidebarData.map((navi) => {
            if (navi.caption) {
              return (
                <div
                  className="navCaption fw-bold text-uppercase mt-4"
                  key={navi.caption}
                >
                  {navi.caption}
                </div>
              );
            }
            if (navi.children) {
              return (
                <NavSubMenu
                  key={navi.id}
                  icon={navi.icon}
                  title={navi.title}
                  items={navi.children}
                  suffix={navi.suffix}
                  suffixColor={navi.suffixColor}
                  isUrl={currentURL === navi.href}
                  className="text-base"
                />
              );
            }
            return (
              <NavItemContainer
                key={navi.id}
                className={location.pathname === navi.href ? "activeLink" : ""}
                to={navi.href}
                title={navi.title}
                suffix={navi.suffix}
                suffixColor={navi.suffixColor}
                icon={navi.icon}
              />
            );
          })}
        </Nav>
      </SimpleBar>
    </div>
  );
};

export default UserSidebar;
