import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

const token =
  localStorage.getItem("token") || localStorage.getItem("employeeToken");

export const getPlotsThunk = createAsyncThunk(
  "plots/getPlotsThunk",
  async ({ name, area, page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/plots/search`, {
        params: {
          name,
          area,
          page,
          limit,
        },
      });
      return response.data; // Returning the fetched data
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Error fetching plots"
      );
    }
  }
);

export const getPlotsByIdThunk = createAsyncThunk(
  "agents/getPlotsByIdThunk",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/plots/getplot/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const bookPlotThunk = createAsyncThunk(
  "agents/bookPlotThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/plots/bookingPlot`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send token in Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getSubPlotsByIdThunk = createAsyncThunk(
  "agents/getSubPlotThunk",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/plots/getsubplot/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAllCouponThunk = createAsyncThunk(
  "agents/getAllCouponThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/coupon/getAllCoupon`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAgentCustomersThunk = createAsyncThunk(
  "agents/getAgentCustomersThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/agents/get_all_customers`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send token in Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getPlotAvailablityThunk = createAsyncThunk(
  "plot/getPlotAvailablityThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/plots/available`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const plotSlice = createSlice({
  name: "plot",
  initialState: {
    loading: false,
    error: null,
    status: "IDLE",
    plots: [],
    currentPage: 1,
    totalPages: 0,
    plot: null,
    subPlot: null,
    coupons: [],
    customers: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlotsThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPlotsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
        state.plots = action.payload.data;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(getPlotsThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getPlotsByIdThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPlotsByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
        state.plot = action.payload.data;
      })
      .addCase(getPlotsByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(bookPlotThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(bookPlotThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
      })
      .addCase(bookPlotThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getSubPlotsByIdThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSubPlotsByIdThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
        state.subPlot = action.payload.data;
      })
      .addCase(getSubPlotsByIdThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getAllCouponThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllCouponThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
        state.coupons = action.payload.coupons;
      })
      .addCase(getAllCouponThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getAgentCustomersThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAgentCustomersThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
        state.customers = action.payload.data;
      })
      .addCase(getAgentCustomersThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      })
      .addCase(getPlotAvailablityThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getPlotAvailablityThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
        state.plots = action.payload.data;
      })
      .addCase(getPlotAvailablityThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "FAILED";
        state.error = action.payload;
      });
  },
});

//generate reducer
const plotReducer = plotSlice.reducer;
export default plotReducer;
