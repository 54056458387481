import React, { useContext, useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import * as Icon from "react-feather";
import { MessageSquare } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  Navbar,
  NavItem,
  UncontrolledDropdown,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import user1 from "../../assets/images/users/user4.jpg";
import MessageDD from "./MessageDD";
import NotificationDD from "./NotificationDD";

// import { ToggleMiniSidebar, ToggleMobileSidebar } from '../../store/customizer/CustomizerSlice';
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../../context";
import {
  ToggleMiniSidebar,
  ToggleMobileSidebar,
} from "../../features/CustomizerSlice";
import {
  getNotificationByUserId,
  updateAllReadNotification,
} from "../../services/axiosInstance";
import ProfileDD from "./ProfileDD";
import { employeeLogoutThunk } from "../../features/authSlice";
import { decodeJwtToken } from "../../utils/decodeJwt";

const Header = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const isDarkMode = useSelector((state) => state.customizer.isDark);
  const topbarColor = useSelector((state) => state.customizer.topbarBg);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setSocket, socket } = useContext(ChatContext);
  const [notifications, setNotifications] = useState([]);
  const token =
    localStorage.getItem("token") || localStorage.getItem("employeeToken");
  const decoded = decodeJwtToken(token);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);

  const getNotification = async () => {
    try {
      const res = await getNotificationByUserId();
      if (res.status == 200) {
        setNotifications(res.data.data);
        setUnreadCount(
          res.data.data.filter((e) => e.status == "unread").length
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateNotification = async () => {
    try {
      const res = await updateAllReadNotification();
      if (res.status == 200) {
        setUnreadCount(0);
        getNotification();
      }
    } catch (error) {
      setError("Refresh Page to get the data");
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     Cookies.remove("token");
  //     localStorage.removeItem("token");
  //     setIsAuthenticated(!isAuthenticated);
  //     navigate("/login");
  //   }
  //   getNotification();
  // }, [isAuthenticated]);

  const handleLogout = () => {
    if (!isAuthenticated || decoded.employee_id === "") {
      Cookies.remove("token");
      localStorage.removeItem("token");
      setIsAuthenticated(!isAuthenticated);
      navigate("/login");
    } else {
      dispatch(employeeLogoutThunk()).then(() => {
        Cookies.remove("employeeToken");
        localStorage.removeItem("employeeToken");
        navigate("/employee-login");
      });
    }
    getNotification();
  };

  useEffect(() => {
    if (socket) {
      socket.on("notification", (data) => {
        const filterData = data.filter((e) => e.user_id == decoded.id);
        const updatedNotifications = [filterData[0], ...notifications];
        setNotifications(updatedNotifications);
        setUnreadCount(unreadCount + 1);
      });
    }
  }, [socket]);

  return (
    <Navbar
      color={topbarColor}
      dark={!isDarkMode}
      light={isDarkMode}
      expand="lg"
      className="topbar"
    >
      {/******************************/}
      {/**********Toggle Buttons**********/}
      {/******************************/}
      <div className="d-flex align-items-center">
        <Button
          color={topbarColor}
          className="d-none d-lg-block"
          onClick={() => dispatch(ToggleMiniSidebar())}
        >
          <Icon.Menu size={22} />
        </Button>
        {/* <div href="/" className="d-sm-flex d-lg-none">
          <Logo />
        </div> */}
        <Button
          color={topbarColor}
          className="d-sm-block d-lg-none"
          onClick={() => dispatch(ToggleMobileSidebar())}
        >
          <Icon.Menu size={22} />
        </Button>
      </div>

      {/******************************/}
      {/**********Left Nav Bar**********/}
      {/******************************/}

      <Nav className="me-auto d-none d-lg-flex" navbar>
        <NavItem className="app-search ps-3">
          <Input
            id="txt-srch"
            name="search"
            placeholder="Search & Enter"
            className="rounded-pill"
            type="text"
          />
        </NavItem>
      </Nav>

      <div className="d-flex align-items-center">
        {/******************************/}
        {/**********Notification DD**********/}
        {/******************************/}
        <UncontrolledDropdown>
          <DropdownToggle color={topbarColor}>
            <div
              style={{ position: "relative" }}
              onClick={() => {
                if (unreadCount > 0) updateNotification();
              }}
            >
              <Icon.Bell size={22} />
              {unreadCount > 0 && (
                <span
                  className="badge bg-danger"
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-10px",
                    fontSize: "0.75rem",
                    padding: "0.2rem 0.4rem",
                  }}
                >
                  {unreadCount}
                </span>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu
            className="ddWidth overflow-y-auto"
            style={{ maxHeight: "440px", overflow: "auto" }}
          >
            <DropdownItem header>
              <span className="mb-0 fs-5 text-dark fw-medium">
                Notifications
              </span>
            </DropdownItem>
            <DropdownItem divider />
            <SimpleBar style={{ maxHeight: "350px" }}>
              {loading ? (
                <div>Loading...</div>
              ) : error ? (
                <div>{error}</div>
              ) : (
                <NotificationDD notifications={notifications} />
              )}
            </SimpleBar>
            <DropdownItem divider />
          </DropdownMenu>
        </UncontrolledDropdown>
        {/******************************/}
        {/**********Message DD**********/}
        {/******************************/}
        <UncontrolledDropdown className="mx-1">
          <DropdownToggle color={topbarColor}>
            <MessageSquare size={22} />
          </DropdownToggle>
          <DropdownMenu className="ddWidth">
            <DropdownItem header>
              <span className="mb-0 fs-5 text-dark fw-medium">Messages</span>
            </DropdownItem>
            <DropdownItem divider />
            <SimpleBar style={{ maxHeight: "350px" }}>
              <MessageDD message={notifications} />
            </SimpleBar>
            <DropdownItem divider />
          </DropdownMenu>
        </UncontrolledDropdown>
        {/******************************/}
        {/**********Mega DD**********/}
        {/******************************/}
        {/* <UncontrolledDropdown className="mega-dropdown mx-1">
          <DropdownToggle
            className="bg-transparent border-0"
            color={topbarColor}
          >
            <Icon.Grid size={22} />
          </DropdownToggle>
          <DropdownMenu>
            <MegaDD />
          </DropdownMenu>
        </UncontrolledDropdown> */}
        {/******************************/}
        {/**********Profile DD**********/}
        {/******************************/}
        <UncontrolledDropdown>
          {/* <DropdownToggle color={topbarColor}>
            <div className="p-2 px-7">
              <button
                className="bg-[rgba(231,196,79,1)] border-none text-[#262626] px-4 py-1.5 rounded-lg text-base hover:bg-white"
                onClick={() => setIsAuthenticated(!isAuthenticated)}
              >
                Logout
              </button>
            </div>
          </DropdownToggle> */}
          <DropdownToggle color={topbarColor}>
            <img
              src={user1}
              alt="profile"
              className="rounded-circle"
              width="30"
            />
          </DropdownToggle>
          <DropdownMenu className="w-40 ">
            <ProfileDD />
            <div className="p-2 px-7 flex justify-center">
              <button
                className="bg-[rgba(231,196,79,1)] border-none text-[#262626] px-4 py-1.5 rounded-lg text-bas"
                onClick={() => handleLogout()}
              >
                Logout
              </button>
            </div>
          </DropdownMenu>
          {/* <DropdownMenu>
            <div className="p-2 px-7">
              <Button
                color="danger"
                size="sm"
                onClick={() => setIsAuthenticated(!isAuthenticated)}
              >
                Logout
              </Button>
            </div>
          </DropdownMenu> */}
        </UncontrolledDropdown>
      </div>
    </Navbar>
  );
};

export default Header;
