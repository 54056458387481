/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React from "react";
import { createRoot } from "react-dom/client";
// import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
// import { store } from './store/Store';
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import store from "./redux/store";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { ChatProvider } from "./context";

// import './data';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ChatProvider>
        <ToastContainer />
        <App />
      </ChatProvider>
    </BrowserRouter>
  </Provider>
);
