// export const BASE_URL = "https://windnxsmart.com";

import { useEffect, useState } from "react";

// export const BASE_URL = "http://192.168.1.44:5000";
export const BASE_URL = "http://localhost:5000";

export const filterObject = (obj) => {
  const filteredObj = {};
  for (const key in obj) {
    // Check if the value is non-null and a non-empty string
    if ([null, "", undefined].includes(obj[key])) {
      continue;
    }
    filteredObj[key] = obj[key];
  }
  return filteredObj;
};

export function convertToSnakeCase(input) {
  if (!input.includes(" ") && !/[A-Z]/.test(input)) {
    return input.toLowerCase(); // Return as is for single word, lowercase
  }

  return input
    .replace(/([a-z])([A-Z])/g, "$1_$2") // Add underscore before capital letters in camelCase
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "_") // Replace spaces with underscores
    .replace(/[^\w\s]/g, ""); // Remove non-word characters (optional)
}

export function convertToNormal(input) {
  if (!input.includes(" ") && !/[A-Z]/.test(input)) {
    return input.toLowerCase(); // Return as is for single word, lowercase
  }

  return input
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add underscore before capital letters in camelCase
    .toLowerCase(); // Convert to lowercase
}

export function formatString(str) {
  return str
    .replace(/_/g, " ") // Replace underscores with spaces
    .toLowerCase() // Convert to lowercase
    .split(" ") // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words back into a single string
}

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const calculateWorkingHours = (checkIn, checkOut) => {
  if (checkIn && checkOut) {
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);
    const diff = checkOutDate - checkInDate; // Difference in milliseconds
    const hours = Math.floor(diff / 1000 / 60 / 60); // Convert to hours
    const minutes = Math.floor((diff / 1000 / 60) % 60); // Convert to minutes
    return `${hours}h ${minutes}m`;
  }
  return "0h 0m"; // Default if no times
};

export const getCurrentMonth = () => {
  const months = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  const today = new Date();
  const month = months[today.getMonth()]; // Get the current month (0-11)
  const year = today.getFullYear(); // Get the current year

  return `${month} ${year}`; // Return in "MONTH YEAR" format
};

export const useDebouncedValue = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler); // Cleanup on value or delay change
    };
  }, [value, delay]);

  return debouncedValue;
};
