import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AccessDeniedPopup from "./AccessDeniedPopup";

// Decode user token utility function (userType only)
export const decodeUserToken = (token) => {
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return {
      id: payload.id,
      userName: payload.user_name || payload.userName,
      phone: payload.phone,
      userType: payload.user_type || payload.role,
      exp: payload.exp || 0,
    };
  } catch (error) {
    console.error("Failed to decode token:", error);
    return {};
  }
};

const UserPrivateRoute = ({ element, requiredUserType }) => {
  const [showAccessDeniedPopup, setShowAccessDeniedPopup] = useState(false);

  const userToken = localStorage.getItem("token");
  const isAuthenticated = !!userToken;
  const token = userToken;
  const decodedToken = isAuthenticated ? decodeUserToken(token) : null;

  // Check for token expiration
  const isTokenExpired =
    decodedToken?.exp && decodedToken.exp < Date.now() / 1000;

  // Show popup if not authenticated, token expired, or userType doesn't match
  if (!isAuthenticated || isTokenExpired) {
    return <Navigate to="/login" replace />;
  }

  if (decodedToken.userType !== requiredUserType) {
    setShowAccessDeniedPopup(true);
    return null; // Don't render Outlet
  }

  // Render the protected content for users
  return (
    <>
      {element ? element : <Outlet />}
      {showAccessDeniedPopup && (
        <AccessDeniedPopup
          message="You do not have access to this page."
          onClose={() => setShowAccessDeniedPopup(false)}
        />
      )}
    </>
  );
};

export default UserPrivateRoute;
