import { User } from "react-feather";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap";
import { formatString } from "../../utils/constant";

const NotificationDD = ({ notifications }) => {
  const notificationStyles = {
    payment_reminder: "warning",
    booking_status: "success",
    document_upload: "info",
  };
  return (
    <div>
      <ListGroup flush>
        {notifications.map((msg) => (
          <ListGroupItem action key={msg._id} tag="a" href="/">
            <div className="d-flex align-items-center gap-3 py-2">
              <div
                className={`circle-box md-box flex-shrink-0 flex justify-center items-center bg-light-${
                  notificationStyles[msg.notification_type]
                } text-${notificationStyles[msg.notification_type]}`}
              >
                <User />
              </div>
              <div className="col-9">
                <ListGroupItemHeading className="mb-0 fw-medium">
                  {formatString(msg.notification_type)}
                </ListGroupItemHeading>
                <ListGroupItemText className="text-muted d-block mb-0">
                  {msg.message}
                </ListGroupItemText>
                <small className="text-muted">
                  {new Date(msg?.createdAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true, // This makes the time format include AM/PM
                  })}
                </small>
              </div>
            </div>
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};

export default NotificationDD;
