import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem("token") || localStorage.getItem("employeeToken");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getUserBookingThunk = createAsyncThunk(
  "book/getUserBookingThunk",
  async (
    { userId, page, limit, sortingColumn, sortingOrder },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get(
        `/api/v1/plots/bookingHistory?page=${page}&limit=${limit}&user_id=${userId}&sortingColumn=${sortingColumn}&sortingOrder=${sortingOrder}`
      );
      console.log(response.data.data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const bookingSlice = createSlice({
  name: "book",
  initialState: {
    books: [],
    loading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserBookingThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserBookingThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.books = action.payload;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(getUserBookingThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const bookingReducer = bookingSlice.reducer;
export default bookingReducer;
