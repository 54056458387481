import React from "react";
import { DropdownItem } from "reactstrap";
import { User, FileText, Star, Settings, Droplet } from "react-feather";
import { Link } from 'react-router-dom';

import user1 from "../../assets/images/users/user1.jpg";

const ProfileDD = () => {
  return (
    <div>
      <DropdownItem className="px-4 py-3 d-flex align-items-center">
        <Link to="/layout/my-profile" className="text-muted d-flex align-items-center no-underline">
        <User size={20} className="text-muted" />
        &nbsp; My Profile
        </Link>
      </DropdownItem>
      <DropdownItem divider />
    </div>
  );
};

export default ProfileDD;
