import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("employeeToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const registerEmployeeThunk = createAsyncThunk(
  "emp/registerEmployeeThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/employee/registerEmployee`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const LoginEmployeeThunk = createAsyncThunk(
  "emp/LoginEmployeeThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/employee/employeeLogin`,
        data,
        {}
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getPermissionThunk = createAsyncThunk(
  "emp/getPermissionThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/employee/get-permission`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addNewRoleThunk = createAsyncThunk(
  "emp/addNewRoleThunk",
  async (roleData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/employee/add-new-role`,
        roleData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAllRolesThunk = createAsyncThunk(
  "emp/getAllRolesThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/employee/get-all-roles`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAllEmployeesThunk = createAsyncThunk(
  "emp/getAllEmployeesThunk",
  async ({ searchQuery = "", page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/employee/get-all-emp`,
        {
          params: { search: searchQuery, page, limit },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch employees"
      );
    }
  }
);

export const updateRolesPermission = createAsyncThunk(
  "emp/updateRolesPermission",
  async (permissionData, { rejectWithValue }) => {
    console.log(permissionData);

    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/employee/update-permission`,
        permissionData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch employees"
      );
    }
  }
);

export const getRolesWithPermissionThunk = createAsyncThunk(
  "emp/getRolesWithPermissionThunk",
  async (roleName, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/employee/roles-with-permission`,
        { role: roleName }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          "Failed to fetch the roles with permissions"
      );
    }
  }
);

export const updateEmployeeThunk = createAsyncThunk(
  "emp/updateEmployeeThunk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/employee/updateEmployee`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    loading: false,
    error: null,
    status: "idle",
    data: {
      permission: [],
      allPermission: [],
      roles: [],
      employees: [],
      totalCount: 0,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerEmployeeThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(registerEmployeeThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
      })
      .addCase(registerEmployeeThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(getPermissionThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getPermissionThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data.allPermission = action.payload.data;
        state.status = "SUCCESS";
      })
      .addCase(getPermissionThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(addNewRoleThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(addNewRoleThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
      })
      .addCase(addNewRoleThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(getAllRolesThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getAllRolesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data.roles = action.payload.data;
        state.status = "SUCCESS";
      })
      .addCase(getAllRolesThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(getAllEmployeesThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getAllEmployeesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data.employees = action.payload.data.data;
        state.data.totalCount = action.payload.data.total;
        state.status = "SUCCESS";
      })
      .addCase(getAllEmployeesThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(updateRolesPermission.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(updateRolesPermission.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
      })
      .addCase(updateRolesPermission.rejected, (state, action) => {
        state.loading = false;
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(LoginEmployeeThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(LoginEmployeeThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
      })
      .addCase(LoginEmployeeThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(getRolesWithPermissionThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getRolesWithPermissionThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data.permission = action.payload.data;
        state.status = "SUCCESS";
      })
      .addCase(getRolesWithPermissionThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(updateEmployeeThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(updateEmployeeThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
      })
      .addCase(updateEmployeeThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "Failed";
        state.error = action.payload;
      });
  },
});

export default employeeSlice.reducer;
