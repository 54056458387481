import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getApprovalDataThunk = createAsyncThunk(
  "approve/getApprovalDataThunk",
  async (
    { page, limit, user_id, payment_reference, sortingColumn, sortingOrder },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/plots/getApprovalData`,
        {
          params: {
            page,
            limit,
            user_id,
            payment_reference,
            sortingColumn,
            sortingOrder,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getBlackAndWhiteDataThunk = createAsyncThunk(
  "approve/getBlackAndWhiteDataThunk",
  async ({ booking_id, payment_reference }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/plots/getBlackAndWhiteData`,
        {
          params: { booking_id, payment_reference },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateRegistrationStatusThunk = createAsyncThunk(
  "approve/updateRegistrationStatus",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/v1/plots/approvedRegistration`,
        formData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

export const updateInstallmentStatusThunk = createAsyncThunk(
  "approve/updateInstallmentStatusThunk",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/v1/plots/approvedInstallment`,
        formData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);

const approveSlice = createSlice({
  name: "approve",
  initialState: {
    loading: false,
    error: null,
    approved: [],
    IsblackAndWhite: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getApprovalDataThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getApprovalDataThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.approved = action.payload.data;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(getApprovalDataThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBlackAndWhiteDataThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBlackAndWhiteDataThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.IsblackAndWhite = action.payload.data;
      })
      .addCase(getBlackAndWhiteDataThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const approveReducer = approveSlice.reducer;
export default approveReducer;
