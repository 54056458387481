import * as Icon from "react-feather";

// Sidebar Data
const EmployeeSidebarData = [
  //employee sidebar
  {
    title: "Dashboard Overview",
    href: "/employee/dashboard",
    id: 1,
    collapisble: false,
    icon: <Icon.Home />,
    requiredPermissions: "VIEW_DASHBOARD",
  },
  {
    title: "User Management",
    href: "/employee/all_customer",
    id: 5,
    icon: <Icon.User />,
    collapisble: true,
    children: [
      {
        title: "Customer Management",
        href: "/employee/all_customer",
        id: 5.1,
        requiredPermissions: "VIEW_ALL_CUSTOMER",
      },
      {
        title: "Agent Management",
        href: "/employee/all_agent",
        id: 5.2,
        requiredPermissions: "VIEW_ALL_AGENT",
      },
      {
        title: "Field Executive Management",
        href: "/employee/all_fieldexecutive",
        id: 5.3,
        requiredPermissions: "VIEW_ALL_FIELDEXECUTIVE",
      },
      {
        title: "Employee Management",
        href: "/employee/all_employee",
        id: 5.4,
        requiredPermissions: "VIEW_ALL_EMPLOYEE",
      },
    ],
  },
  {
    title: "Plot Management",
    href: "/employee/plot_management",
    id: 6,
    icon: <Icon.Map />,
    collapisble: true,
    children: [
      {
        title: "Plot Site Management",
        href: "/employee/plot_management",
        id: 6.1,
        requiredPermissions: "VIEW_PLOT_MANAGEMENT",
      },
    ],
  },
  {
    title: "Booking Management",
    href: "/employee/all_bookings",
    id: 7,
    icon: <Icon.Book />,
    collapisble: true,
    children: [
      {
        title: "Booking Approval",
        href: "/employee/approval_workflow",
        id: 7.1,
        requiredPermissions: "VIEW_APPROVAL_WORKFLOW",
      },
      {
        title: "Registration Approval",
        href: "/employee/registeration_approval",
        id: 7.2,
        requiredPermissions: "VIEW_REGISTERATION_APPROVAL",
      },
      {
        title: "Installment Approval",
        href: "/employee/installment_approval",
        id: 7.3,
        requiredPermissions: "VIEW_INSTALLMENT_APPROVAL",
      },
      {
        title: "View All Bookings",
        href: "/employee/all_bookings",
        id: 7.4,
        requiredPermissions: "VIEW_ALL_BOOKINGS",
      },
    ],
  },
  {
    title: "Sales and Commission",
    href: "/employee/sales_performance",
    id: 8,
    icon: <Icon.BarChart2 />,
    collapisble: true,
    children: [
      {
        title: "Sales Performance",
        href: "/employee/sales_performance",
        id: 8.1,
        requiredPermissions: "VIEW_SALES_PERFORMANCE",
      },
      {
        title: "Commission Management",
        href: "/employee/comission_management",
        id: 8.2,
        requiredPermissions: "VIEW_COMISSION_MANAGEMENT",
      },
      {
        title: "Agent Wallet",
        href: "/employee/agent_wallet",
        id: 8.3,
        requiredPermissions: "VIEW_AGENT_WALLET",
      },
    ],
  },
  {
    title: "Document Management",
    href: "/employee/customer_documents",
    id: 9,
    icon: <Icon.Columns />,
    collapisble: true,
    children: [
      {
        title: "Customer Documents",
        href: "/employee/customer_documents",
        id: 9.1,
        requiredPermissions: "VIEW_CUSTOMER_DOCS",
      },
      {
        title: "Agent Documents",
        href: "/employee/agent_documents",
        id: 9.2,
        requiredPermissions: "VIEW_AGENT_DOCS",
      },
    ],
  },
  // {
  //   title: "CRM",
  //   href: "/employee/crm",
  //   id: 10,
  //   icon: <Icon.User />,
  //   collapisble: true,
  //   children: [
  //     {
  //       title: "Customer Relationship",
  //       href: "/employee/crm",
  //       id: 10.1,
  //       requiredPermissions: "VIEW_CRM",
  //     },
  //     {
  //       title: "Sales Pipeline",
  //       href: "/employee/sales_pipeline",
  //       id: 10.2,
  //       requiredPermissions: "VIEW_SALES_PIPELINE",
  //     },
  //   ],
  // },
  {
    title: "Payment Management",
    href: "/employee/payment_tracking",
    id: 11,
    icon: <Icon.CreditCard />,
    collapisble: true,
    children: [
      {
        title: "Payment Tracking",
        href: "/employee/payment_tracking",
        id: 11.1,
        requiredPermissions: "VIEW_PAYMENT_TRACKING",
      },
      {
        title: "Commission Payouts",
        href: "/employee/payout",
        id: 11.2,
        requiredPermissions: "VIEW_PAYOUT",
      },
    ],
  },
  {
    title: "Chats",
    href: "/employee/chats",
    icon: <Icon.Activity />,
    id: 12,
    collapisble: false,
  },
  {
    title: "Accounts and Finance",
    href: "/employee/payment_management",
    id: 13,
    icon: <Icon.Briefcase />,
    collapisble: true,
    children: [
      {
        title: "Payment Management System",
        href: "/employee/payment_management",
        id: 13.1,
        requiredPermissions: "VIEW_PAYMENT_MANAGEMENT",
      },
    ],
  },
  {
    title: "Attendance",
    href: "/employee/attendance",
    id: 14,
    icon: <Icon.Users />,
    collapisble: true,
    children: [
      {
        title: "Attendance List",
        href: "/employee/attendance",
        id: 14.1,
        requiredPermissions: "VIEW_EMPLOYEE_ATTENDANCE",
      },
      {
        title: "Attendance Catalog",
        href: "/employee/attendance_catalog",
        id: 14.2,
        requiredPermissions: "VIEW_ATTENDANCE_CATALOG",
      },
      {
        title: "Payroll",
        href: "/employee/payroll",
        id: 14.3,
        requiredPermissions: "VIEW_EMPLOYEE_PAYROLL",
      },
    ],
  },
  {
    title: "Reports and Analytics",
    href: "/employee/reports/individual_reports",
    id: 15,
    icon: <Icon.Flag />,
    collapisble: true,
    children: [
      {
        title: "Individual Reports",
        href: "/employee/reports/individual_reports",
        id: 15.1,
        requiredPermissions: "VIEW_INDIVIDUAL_REPORTS",
      },
      {
        title: "Booking Reports",
        href: "/employee/reports/booking_reports",
        id: 15.2,
        requiredPermissions: "VIEW_BOOKING_REPORTS",
      },
      {
        title: "Approval Report",
        href: "/employee/reports/approval_reports",
        id: 15.3,
        requiredPermissions: "VIEW_APPROVAL_REPORTS",
      },
      {
        title: "Cancelled Report",
        href: "/employee/reports/cancelled_reports",
        id: 15.4,
        requiredPermissions: "VIEW_CANCELLED_REPORTS",
      },
      {
        title: "Agent Performance Report",
        href: "/employee/reports/agent_performance_reports",
        id: 15.5,
        requiredPermissions: "VIEW_AGENT_PERFORMANCE_REPORTS",
      },
      {
        title: "Comission Report",
        href: "/employee/reports/comission_reports",
        id: 15.6,
        requiredPermissions: "VIEW_COMISSION_REPORTS",
      },
      {
        title: "Documents Status Report",
        href: "/employee/reports/document_status_reports",
        id: 15.7,
        requiredPermissions: "VIEW_DOCUMENTS_REPORTS",
      },
    ],
  },
  {
    title: "Manage Promotions",
    href: "/employee/promotion_banner",
    id: 16,
    icon: <Icon.Percent />,
    collapisble: true,
    children: [
      {
        title: "Promotion Banner",
        href: "/employee/promotion_banner",
        id: 14.1,
        requiredPermissions: "VIEW_ALL_BANNERS",
      },
      {
        title: "Coupons",
        href: "/employee/coupons",
        id: 14.2,
        requiredPermissions: "VIEW_ALL_COUPONS",
      },
    ],
  },
  // {
  //   title: "Profile & Settings",
  //   href: "/employee/my_profile",
  //   icon: <Icon.User />,
  //   id: 17,
  //   collapisble: false,
  //   requiredPermissions: "VIEW_PROFILE",
  // },
];

export default EmployeeSidebarData;
