import * as Icon from "react-feather";

const UserSidebarData = [
  // Admin & Agent Sections
  {
    title: "Dashboard",
    href: "/agent/dashboard",
    icon: <Icon.Activity />,
    id: 1,
    collapisble: false,
    requiredUserType: "agent",
  },
  {
    title: "Lead Management",
    href: "/agent/lead_management",
    icon: <Icon.Mail />,
    id: 2,
    collapisble: false,
    requiredUserType: "agent",
  },
  {
    title: "Plot Management",
    href: "/agent/plot_list",
    icon: <Icon.Menu />,
    id: 3,
    collapisble: false,
    requiredUserType: "agent",
  },
  {
    title: "Booking Management",
    href: "/agent/booking_management",
    icon: <Icon.Book />,
    id: 4,
    collapisble: false,
    requiredUserType: "agent",
  },
  {
    title: "Customer Management",
    href: "/agent/customer_management",
    icon: <Icon.Monitor />,
    id: 5,
    collapisble: false,
    requiredUserType: "agent",
  },
  {
    title: "Commission Tracking",
    href: "/agent/agent_comission",
    icon: <Icon.Pocket />,
    id: 6,
    collapisble: false,
    requiredUserType: "agent",
  },
  {
    title: "Task Management",
    href: "/agent/task_management",
    icon: <Icon.Table />,
    id: 7,
    collapisble: false,
    requiredUserType: "agent",
  },
  {
    title: "Document Management",
    href: "/agent/documents_upload",
    icon: <Icon.FileText />,
    id: 8,
    collapisble: false,
    requiredUserType: "agent",
  },
  {
    title: "Support & FAQs",
    href: "/agent/faqs",
    icon: <Icon.Headphones />,
    id: 9,
    collapisble: false,
    requiredUserType: "agent",
  },
  {
    title: "Profile & Settings",
    href: "/agent/my_profile",
    icon: <Icon.User />,
    id: 10,
    collapisble: false,
    requiredUserType: "agent",
  },

  // Customer Sections
  {
    title: "Home",
    href: "/customer/dashboard",
    icon: <Icon.Home />,
    id: 11,
    collapisble: false,
    requiredUserType: "customer",
  },
  {
    title: "Book Plots",
    href: "/customer/plot_list",
    icon: <Icon.Pocket />,
    id: 12,
    collapisble: false,
    requiredUserType: "customer",
  },
  {
    title: "My Bookings",
    href: "/customer/my_bookings",
    icon: <Icon.Database />,
    id: 13,
    collapisble: false,
    requiredUserType: "customer",
  },
  {
    title: "Payments",
    href: "/customer/payments",
    icon: <Icon.Pocket />,
    id: 14,
    collapisble: false,
    requiredUserType: "customer",
  },
  {
    title: "Documents",
    href: "/customer/documents_uploads",
    icon: <Icon.FileText />,
    id: 15,
    collapisble: false,
    requiredUserType: "customer",
  },
  {
    title: "Customer Support",
    href: "/customer/faqs",
    icon: <Icon.Headphones />,
    id: 16,
    collapisble: false,
    requiredUserType: "customer",
  },
  {
    title: "Profile & Settings",
    href: "/customer/my_profile",
    icon: <Icon.User />,
    id: 17,
    collapisble: false,
    requiredUserType: "customer",
  },
];

export default UserSidebarData;
