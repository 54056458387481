import { jwtDecode } from "jwt-decode";
const getCookie = (name) => {
  const cookieArr = document.cookie.split("; ");
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0]) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

export const getUserIdFromToken = () => {
  const token = getCookie("token");

  if (token) {
    try {
      const decoded = jwtDecode(token);
      const userId = decoded?.id;
      return userId;
    } catch (error) {
      console.error("Invalid token or unable to decode");
    }
  } else {
    console.error("No token found");
  }
  return null;
};

export const getUserTypeFromToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const decoded = jwtDecode(token);
      const userType = decoded?.user_type;
      return userType;
    } catch (error) {
      console.error("Invalid token or unable to decode");
    }
  } else {
    console.error("No token found");
  }
};

export const decodeToken = (token) => {
  if (token) {
    try {
      const decoded = jwtDecode(token);
      const userType = decoded?.user_type;
      return userType;
    } catch (error) {
      return false;
      console.error("Invalid token or unable to decode");
    }
  } else {
    return false;
    console.error("No token found");
  }
};

export const decodeJwtToken = (token) => {
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return {
      id: payload.id,
      employee_id: payload.employee_id || "",
      userName: payload.user_name || payload.userName,
      phone: payload.phone,
      role: payload.role || payload.user_type,
      userType: payload.user_type || payload.role,
      permissions: payload.permissions || [],
      exp: payload.exp || 0,
    };
  } catch (error) {
    console.error("Failed to decode token:", error);
    return {};
  }
};
