import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

// Thunk to fetch user profile data
export const getProfileThunk = createAsyncThunk(
  "profile/getProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/api/v1/users/getProfileData");
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Thunk to update user profile data
export const updateProfileThunk = createAsyncThunk(
  "profile/updateProfile",
  async ({ id, address, profilePhoto }, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("newAddress", address);
    if (profilePhoto) {
      formData.append("profile_photo", profilePhoto);
    }

    try {
      const response = await axios.put(
        `/api/v1/users/updateUserDetail/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    loading: false,
    error: null,
    profile: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfileThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProfileThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(getProfileThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateProfileThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfileThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = {
          ...state.profile,
          contact_details: {
            ...state.profile?.contact_details,
            address: action.payload?.contact_details.address || state.profile?.contact_details.address, 
          },
          documents: {
            ...state.profile?.documents,
            profile_photo: action.payload?.documents?.profile_photo || state.profile?.documents?.profile_photo,  
          },
        };
      })
      .addCase(updateProfileThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const profileReducer = profileSlice.reducer;
export default profileReducer;
