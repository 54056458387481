import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getDocumentVerifyThunk = createAsyncThunk(
  "document/getDocumentVerifyThunk",
  async (
    { page, limit, user_type, sortingColumn, sortingOrder, filter },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post("/api/v1/users/getDocumentsVerify", {
        page,
        limit,
        user_type,
        sortingColumn,
        sortingOrder,
        filter,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const verifyDocumentThunk = createAsyncThunk(
  "document/verifyDocumentThunk",
  async ({ _id, status, reason }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/v1/users/verifyDocument", {
        _id,
        status,
        reason,
      });
      return response.data;
    } catch (error) {
      console.error("Error verifying document:", error)
      return rejectWithValue(error.response.data.message);
    }
  }
);

const documentSlice = createSlice({
  name: "document",
  initialState: {
    documents: [],
    totalDocuments: 0,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentVerifyThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDocumentVerifyThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = action.payload.data;
        state.totalDocuments = action.payload.totalDocuments
      })
      .addCase(getDocumentVerifyThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(verifyDocumentThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyDocumentThunk.fulfilled, (state, action) => {
        state.loading = false;
        const updatedDocument = action.payload;
        state.documents = state.documents.map(document =>
          document._id === updatedDocument._id ? updatedDocument : document
        );
      })
      .addCase(verifyDocumentThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const documentReducer = documentSlice.reducer;
export default documentReducer;
