import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getBookingReportsThunk = createAsyncThunk(
  "report/getBookingReportsThunk",
  async ({ bookingId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v1/report/allBookingReport?reportType=bookingDetails&page=1&limit=10&bookingId=${bookingId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getApprovalReportThunk = createAsyncThunk(
  "report/getApprovalReportThunk",
  async ({ page, limit, approvalStatus }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v1/report/allBookingReport?reportType=pendingApprovals&approvalStatus=${approvalStatus}&page=${page}&limit=${limit}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getCancelledReportThunk = createAsyncThunk(
  "report/getCancelledReportThunk",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v1/report/allBookingReport?reportType=canceledBookings&page=${page}&limit=${limit}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAgentPerformance = createAsyncThunk(
  "report/getAgentPerformanceThunk",
  async ({ subType, agentId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v1/report/salesPerformanceReport?reportType=agentPerformance&subType=${subType}&agentId=${agentId}&startDate=2024-11-08&endDate=2024-11-15`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getDocumentReportThunk = createAsyncThunk(
  "report/getDocumentReportThunk",
  async (
    { reportType, userType, verificationStatus, startDate, endDate },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get(
        `/api/v1/report/documentReport?reportType=${reportType}&userType=${userType}&verificationStatus=${verificationStatus}&startDate=2024-11-08&endDate=2024-11-15`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getCommissionReportThunk = createAsyncThunk(
  "report/getCommissionReportThunk",
  async (
    { reportType, subType, agentId, startDate, endDate },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get(
        `/api/v1/report/commissionReport?reportType=agent-commission-report&subType=pendingPayout&agentId=671b931db7b7eabdd19a6488&startDate=2024-11-10&endDate=2024-11-16`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getExpenseReportThunk = createAsyncThunk(
  "report/getExpenseReportThunk",
  async (
    { reportType, subType, agentId, startDate, endDate },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get(
        `/api/v1/report/expenseReport?reportType=payroll-report&subType=pending&startDate=2024-01-01&endDate=2024-11-25`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState: {
    reports: [],
    approvalReports: [],
    cancelledReports: [],
    performance: [],
    documentReport: [],
    commissionReport: [],
    expenseReport: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBookingReportsThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBookingReportsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.reports = action.payload.data;
      })
      .addCase(getBookingReportsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "An error occurred";
      })
      .addCase(getApprovalReportThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getApprovalReportThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.approvalReports = action.payload.pendingApprovals;
      })
      .addCase(getApprovalReportThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "An error occurred";
      })
      .addCase(getCancelledReportThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCancelledReportThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.cancelledReports = action.payload.data;
      })
      .addCase(getCancelledReportThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "An error occurred";
      })
      .addCase(getAgentPerformance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAgentPerformance.fulfilled, (state, action) => {
        state.loading = false;
        state.performance = action.payload;
      })
      .addCase(getAgentPerformance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "An error occurred";
      })
      .addCase(getDocumentReportThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDocumentReportThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.documentReport = action.payload.data;
      })
      .addCase(getDocumentReportThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "An error occurred";
      })
      .addCase(getCommissionReportThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCommissionReportThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.commissionReport = action.payload.data;
      })
      .addCase(getCommissionReportThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "An error occurred";
      })
      .addCase(getExpenseReportThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getExpenseReportThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.expenseReport = action.payload.data;
      })
      .addCase(getExpenseReportThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "An error occurred";
      });
  },
});

const reportReducer = reportSlice.reducer;
export default reportReducer;
