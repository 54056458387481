import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getFieldExecutivesThunk = createAsyncThunk(
  "admin/getFieldExecutivesThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/admin/get_field_executive`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getFieldExecutivesByNameThunk = createAsyncThunk(
  "admin/getFieldExecutivesByNameThunk",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/admin/get_field_executive_by_name`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const assignTaskByAdminThunk = createAsyncThunk(
  "admin/assignTaskByAdminThunk",
  async (taskData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/task/assign-task-by-admin`,
        taskData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    loading: false,
    error: null,
    status: "idle",
    data: {
      fieldExecutive: [],
      fieldExecutiveByName: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFieldExecutivesThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getFieldExecutivesThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data.fieldExecutive = action.payload.data;
        state.status = "SUCCESS";
      })
      .addCase(getFieldExecutivesThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(assignTaskByAdminThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(assignTaskByAdminThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
      })
      .addCase(assignTaskByAdminThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "Failed";
        state.error = action.payload;
      })
      .addCase(getFieldExecutivesByNameThunk.pending, (state, action) => {
        state.loading = true;
        state.status = "LOADING";
      })
      .addCase(getFieldExecutivesByNameThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "SUCCESS";
        state.data.fieldExecutiveByName = action.payload.data;
      })
      .addCase(getFieldExecutivesByNameThunk.rejected, (state, action) => {
        state.loading = false;
        state.status = "Failed";
        state.error = action.payload;
      });
  },
});

export default adminSlice.reducer;
