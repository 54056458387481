import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AccessDeniedPopup from "./AccessDeniedPopup";
import { useDispatch } from "react-redux";
import { getRolesWithPermissionThunk } from "../features/employeeSlice";

// Decode employee token utility function
export const decodeEmployeeToken = (token) => {
  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    return {
      id: payload.id,
      employee_id: payload.employee_id || "",
      userName: payload.user_name || payload.userName,
      phone: payload.phone,
      role: payload.role || "",
      permissions: payload.permissions || [],
      exp: payload.exp || 0,
    };
  } catch (error) {
    console.error("Failed to decode token:", error);
    return {};
  }
};

const EmployeePrivateRoute = ({ element, allowedPermissions }) => {
  const dispatch = useDispatch();
  const [showAccessDeniedPopup, setShowAccessDeniedPopup] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);

  const employeeToken = localStorage.getItem("employeeToken");
  const isAuthenticated = !!employeeToken;
  const decodedToken = isAuthenticated
    ? decodeEmployeeToken(employeeToken)
    : null;

  const roleName = decodedToken?.role;
  const isTokenExpired =
    decodedToken?.exp && decodedToken.exp < Date.now() / 1000;

  const fetchPermissions = useCallback(async () => {
    if (!roleName) return;
    try {
      const response = await dispatch(getRolesWithPermissionThunk(roleName));
      if (
        response.meta.requestStatus === "fulfilled" &&
        response.payload.data.name === roleName
      ) {
        setPermissions(response.payload.data.permissions || []);
      }
    } catch (error) {
      console.error("Failed to fetch permissions:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, roleName]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  const hasRequiredPermissions = useMemo(() => {
    if (loading) return false;

    const normalizedAllowedPermissions = Array.isArray(allowedPermissions)
      ? allowedPermissions
      : [allowedPermissions];

    const isRequiredPermissions = normalizedAllowedPermissions.every(
      (permission) => permissions.includes(permission)
    );

    return isRequiredPermissions;
  }, [allowedPermissions, permissions, loading]);

  if (!isAuthenticated || isTokenExpired) {
    return <Navigate to="/employee-login" replace />;
  }

  if (!roleName) {
    console.error("No role found in the token.");
    return <Navigate to="/employee-login" replace />;
  }

  if (loading) {
    return <div>Loading...</div>; // Add a better loading UI if needed
  }

  if (!hasRequiredPermissions) {
    if (!showAccessDeniedPopup) {
      setShowAccessDeniedPopup(true); // Safely trigger popup without causing re-render loops
    }
    return null;
  }

  return (
    <>
      {element ? element : <Outlet />}
      {showAccessDeniedPopup && (
        <AccessDeniedPopup
          message="You do not have access to this page."
          onClose={() => setShowAccessDeniedPopup(false)}
        />
      )}
    </>
  );
};

export default EmployeePrivateRoute;
