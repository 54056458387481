import { lazy } from "react";
import Loadable from "../layouts/loader/Loadable";
import EmployeePrivateRoute from "../components/EmployeePrivateRoute";
import UserPrivateRoute from "../components/UserPrivateRoute";
import FullLayout from "../layouts/FullLayout";
import { Navigate } from "react-router-dom";

// Layouts and Pages
const BookingManagement = Loadable(
  lazy(() => import("../pages/Agent/bookingManagement"))
);
const RegistrationApproval = Loadable(
  lazy(() => import("../components/employee/registrationApproval"))
);
const InstallmentApproval = Loadable(
  lazy(() => import("../components/employee/installmentApproval"))
);
const SalePipeline = Loadable(
  lazy(() => import("../components/Agent/salesAndCommission/salePipeline"))
);
const AllBookings = Loadable(
  lazy(() => import("../components/superAdmin/bookings/allBookings"))
);
const ChatApp = Loadable(
  lazy(() => import("../components/superAdmin/chat/Chat"))
);
const IndividualReports = Loadable(
  lazy(() => import("../components/superAdmin/reports/individualReports"))
);
const ApprovalReport = Loadable(
  lazy(() => import("../components/superAdmin/reports/booking/approvalReport"))
);
const CancelledReport = Loadable(
  lazy(() => import("../components/superAdmin/reports/booking/cancelledReport"))
);
const AgentPerformance = Loadable(
  lazy(() =>
    import(
      "../components/superAdmin/reports/salesAndPerformance/agentPerformance"
    )
  )
);
const CommissionReport = Loadable(
  lazy(() =>
    import("../components/superAdmin/reports/commission/commissionReport")
  )
);
const DocumentStatus = Loadable(
  lazy(() =>
    import("../components/superAdmin/reports/documentReports/documentStatus")
  )
);
const BookingReport = Loadable(
  lazy(() => import("../components/superAdmin/reports/booking/bookingReport"))
);

const Dashboard = Loadable(
  lazy(() => import("../pages/Agent/Dashboard/dashboard"))
);
const ApprovalWorkflow = Loadable(
  lazy(() => import("../components/superAdmin/bookings/approvalWorkflow"))
);
const BookPlot = Loadable(lazy(() => import("../components/Agent/BookPlot")));
const LeadDetails = Loadable(
  lazy(() => import("../components/Agent/leadDetails"))
);
const Commission = Loadable(
  lazy(() => import("../pages/Agent/Commission/commission"))
);
const CustomerManagement = Loadable(
  lazy(() => import("../pages/Agent/CustomerManagement/customerManagement"))
);
const LeadManagement = Loadable(
  lazy(() => import("../pages/Agent/LeadManagement/leadManagement"))
);
const Login = Loadable(lazy(() => import("../pages/Agent/Login/login")));
const EmployeeLogin = Loadable(lazy(() => import("../pages/employeeLogin")));
const OtpVerify = Loadable(
  lazy(() => import("../pages/Agent/OtpVerify/OtpVerify"))
);
const Register = Loadable(
  lazy(() => import("../pages/Agent/Register/Register"))
);
const TaskManagement = Loadable(
  lazy(() => import("../pages/Agent/TaskManagement/taskManagement"))
);
const Forbidden = Loadable(lazy(() => import("../pages/forbidden")));
const PlotGrid = Loadable(lazy(() => import("../components/sample/PlotGrid")));
const ShopListing = Loadable(lazy(() => import("../components/shoplist")));
const DocumentDashboard = Loadable(
  lazy(() => import("../components/Document"))
);
const FAQSection = Loadable(lazy(() => import("../components/faqSection")));
const ProfileScreen = Loadable(lazy(() => import("../components/Profile")));
const MyBookingPlot = Loadable(
  lazy(() => import("../pages/Agent/MyBookingPlot/MyBookingPlot"))
);
const PaymentDashboard = Loadable(
  lazy(() => import("../pages/Agent/PaymentDashboard/PaymentDashboard"))
);
const SuperAdminDashboard = Loadable(
  lazy(() => import("../components/superAdmin/superAdminDashboard"))
);
const AgentManagement = Loadable(
  lazy(() => import("../components/superAdmin/userManagement/agentManagement"))
);
const CustomerDetail = Loadable(
  lazy(() => import("../components/superAdmin/userManagement/customerDetail"))
);
const EmployeeDashboard = Loadable(
  lazy(() =>
    import(
      "../pages/Agent/UserManagement/EmployeeManagement/EmployeeManagement"
    )
  )
);
const DocumentRepo = Loadable(
  lazy(() => import("../components/superAdmin/documentManagement/documentRepo"))
);
const CRM = Loadable(lazy(() => import("../components/superAdmin/crm/crm")));
const PromotionBanner = Loadable(
  lazy(() => import("../components/superAdmin/promotions/promotionBanner"))
);
const Coupons = Loadable(
  lazy(() => import("../components/superAdmin/promotions/coupons"))
);
const PlotManagement = Loadable(
  lazy(() => import("../components/superAdmin/plotManagement"))
);
const CreatePlot = Loadable(lazy(() => import("../components/CreatePlot")));
const UpdatePlot = Loadable(lazy(() => import("../components/updatePlot")));
const FieldExecutiveManagement = Loadable(
  lazy(() =>
    import("../pages/Admin/FieldExecutiveManagement/FieldExecutiveManagement")
  )
);
const CustomerDashboard = Loadable(
  lazy(() => import("../components/customerDashboard"))
);
const PaymentTracking = Loadable(
  lazy(() => import("../components/paymentTracking"))
);
const Payout = Loadable(lazy(() => import("../components/payout")));
const SalePerformance = Loadable(
  lazy(() => import("../components/Agent/salesAndCommission/SalePerformance"))
);
const CommissionManagement = Loadable(
  lazy(() =>
    import("../components/Agent/salesAndCommission/CommissionManagement")
  )
);
const AgentWallet = Loadable(
  lazy(() => import("../components/Agent/salesAndCommission/agentWallet"))
);
const PaymentManagement = Loadable(
  lazy(() =>
    import("../components/superAdmin/accountAndFinance/paymentManagement")
  )
);
const Attendance = Loadable(
  lazy(() => import("../components/superAdmin/accountAndFinance/attendance"))
);
const Map = Loadable(
  lazy(() => import("../components/superAdmin/accountAndFinance/map"))
);
const AttendanceCatalog = Loadable(
  lazy(() =>
    import("../components/superAdmin/accountAndFinance/attendanceManual")
  )
);
const Payroll = Loadable(
  lazy(() => import("../components/superAdmin/accountAndFinance/payroll"))
);

// // Employee Routes
// const employeeRoutes = [
//   {
//     path: "/employee/dashboard",
//     element: <SuperAdminDashboard />,
//     allowedPermissions: "VIEW_DASHBOARD",
//   },
//   {
//     path: "/employee/approval_workflow",
//     element: <ApprovalWorkflow />,
//     allowedPermissions: "VIEW_APPROVAL_WORKFLOW",
//   },
//   {
//     path: "/employee/registeration_workflow",
//     element: <RegistrationApproval />,
//     allowedPermissions: "VIEW_REGISTERATION_WORKFLOW",
//   },
//   {
//     path: "/employee/installment_approval",
//     element: <RegistrationApproval />,
//     allowedPermissions: "VIEW_INSTALLMENT_APPROVAL",
//   },
//   {
//     path: "/employee/customer_documents",
//     element: <DocumentRepo userType="customer" />,
//     allowedPermissions: "VIEW_CUSTOMER_DOCS",
//   },
//   {
//     path: "/employee/agent_documents",
//     element: <DocumentRepo userType="agent" />,
//     allowedPermissions: "VIEW_AGENT_DOCS",
//   },
//   {
//     path: "/employee/crm",
//     element: <CRM />,
//     allowedPermissions: "VIEW_CRM",
//   },
//   {
//     path: "/employee/sales_pipeline",
//     element: <SalePipeline />,
//     allowedPermissions: "VIEW_SALES_PIPELINE",
//   },
//   {
//     path: "/employee/all_customer",
//     element: <CustomerDetail userType="customer" />,
//     allowedPermissions: "VIEW_ALL_CUSTOMER",
//   },
//   {
//     path: "/employee/all_agent",
//     element: <AgentManagement userType="agent" />,
//     allowedPermissions: "VIEW_ALL_AGENT",
//   },
//   {
//     path: "/employee/all_fieldexecutive",
//     element: <FieldExecutiveManagement />,
//     allowedPermissions: "VIEW_ALL_FIELDEXECUTIVE",
//   },
//   {
//     path: "/employee/plot_management",
//     element: <PlotManagement />,
//     allowedPermissions: "VIEW_PLOT_MANAGEMENT",
//   },
//   {
//     path: "/employee/all_employee",
//     element: <EmployeeDashboard />,
//     allowedPermissions: "VIEW_ALL_EMPLOYEE",
//   },
//   {
//     path: "/employee/create_plot",
//     element: <CreatePlot />,
//     allowedPermissions: "VIEW_CREATE_PLOT",
//   },
//   {
//     path: "/employee/edit_plot/:id",
//     element: <UpdatePlot />,
//     allowedPermissions: "VIEW_UPDATE_PLOT",
//   },
//   {
//     path: "/employee/all_bookings",
//     element: <AllBookings />,
//     allowedPermissions: "VIEW_ALL_BOOKINGS",
//   },
//   {
//     path: "/employee/banners",
//     element: <PromotionBanner />,
//     allowedPermissions: "VIEW_PROMOTION_BANNER",
//   },
//   {
//     path: "/employee/payment_tracking",
//     element: <PaymentTracking />,
//     allowedPermissions: "VIEW_PAYMENT_TRACKING",
//   },
//   {
//     path: "/employee/payout",
//     element: <Payout />,
//     allowedPermissions: "VIEW_PAYOUT",
//   },
//   {
//     path: "/employee/sales_performance",
//     element: <SalePerformance />,
//     allowedPermissions: "VIEW_SALES_PERFORMANCE",
//   },
//   {
//     path: "/employee/comission_management",
//     element: <CommissionManagement />,
//     allowedPermissions: "VIEW_COMISSION_MANAGEMENT",
//   },
//   {
//     path: "/employee/plot_detail/:id",
//     element: <PlotGrid />,
//     allowedPermissions: "VIEW_PLOT_DETAILS",
//   },
//   {
//     path: "/employee/agent_wallet",
//     element: <AgentWallet />,
//     allowedPermissions: "VIEW_AGENT_WALLET",
//   },
//   {
//     path: "/employee/payment_management",
//     element: <PaymentManagement />,
//     allowedPermissions: "VIEW_PAYMENT_MANAGEMENT",
//   },
//   {
//     path: "/employee/attendance",
//     element: <Attendance />,
//     allowedPermissions: "VIEW_EMPLOYEE_ATTENDANCE",
//   },
//   {
//     path: "/employee/attendance/:employeeId/:month/:year/:day",
//     element: <Map />,
//     allowedPermissions: "VIEW_ATTENDANCE_MAP",
//   },
//   {
//     path: "/employee/attendance_catalog",
//     element: <AttendanceCatalog />,
//     allowedPermissions: "VIEW_ATTENDANCE_CATALOG",
//   },
//   {
//     path: "/employee/payroll",
//     element: <Payroll />,
//     allowedPermissions: "VIEW_EMPLOYEE_PAYROLL",
//   },
//   {
//     path: "/employee/chats",
//     element: <ChatApp />,
//     allowedPermissions: "VIEW_EMPLOYEE_CHAT",
//   },
//   {
//     path: "/employee/reports/individual_reports",
//     element: <IndividualReports />,
//     allowedPermissions: "VIEW_INDIVIDUAL_REPORTS",
//   },
//   {
//     path: "/employee/reports/booking_reports",
//     element: <BookingReport />,
//     allowedPermissions: "VIEW_BOOKING_REPORTS",
//   },
//   {
//     path: "/employee/reports/approval_reports",
//     element: <ApprovalReport />,
//     allowedPermissions: "VIEW_APPROVAL_REPORTS",
//   },
//   {
//     path: "/employee/reports/cancelled_reports",
//     element: <CancelledReport />,
//     allowedPermissions: "VIEW_CANCELLED_REPORTS",
//   },
//   {
//     path: "/employee/reports/agent_performance_reports",
//     element: <AgentPerformance />,
//     allowedPermissions: "VIEW_AGENT_PERFORMANCE_REPORTS",
//   },
//   {
//     path: "/employee/reports/comission_reports",
//     element: <CommissionReport />,
//     allowedPermissions: "VIEW_COMISSION_REPORTS",
//   },
//   {
//     path: "/employee/reports/document_status_reports",
//     element: <DocumentStatus />,
//     allowedPermissions: "VIEW_DOCUMENTS_REPORT",
//   },
// ];

// // User Routes
// const userRoutes = [
//   {
//     path: "/agent/dashboards",
//     element: <Dashboard />,
//     requiredUserType: "agent",
//   },
//   {
//     path: "/agent/plot_list",
//     element: <ShopListing />,
//     requiredUserType: "agent",
//   },
//   {
//     path: "/agent/plot_detail/:id",
//     element: <PlotGrid />,
//     requiredUserType: "agent",
//   },
//   {
//     path: "/agent/booking_management",
//     element: <BookingManagement />,
//     requiredUserType: "agent",
//   },
//   {
//     path: "/agent/book_plot",
//     element: <BookPlot />,
//     requiredUserType: "agent",
//   },
//   {
//     path: "/agent/agent_comission",
//     element: <Commission />,
//     requiredUserType: "agent",
//   },
//   {
//     path: "/agent/task_management",
//     element: <TaskManagement />,
//     requiredUserType: "agent",
//   },
//   {
//     path: "/agent/customer_management",
//     element: <CustomerManagement />,
//     requiredUserType: "agent",
//   },
//   {
//     path: "/agent/lead_management",
//     element: <LeadManagement />,
//     requiredUserType: "agent",
//   },
//   {
//     path: "/agent/lead_details/:id",
//     element: <LeadDetails />,
//     requiredUserType: "agent",
//   },
//   {
//     path: "/agent/documents_upload",
//     element: <DocumentDashboard />,
//     requiredUserType: "agent",
//   },
//   {
//     path: "/agent/faqs",
//     element: <FAQSection />,
//     requiredUserType: "agent",
//   },
//   {
//     path: "/agent/my_profile",
//     element: <ProfileScreen />,
//     requiredUserType: "agent",
//   },
//   // ================================= Customer Routes =====================================
//   {
//     path: "/customer/dashboard",
//     element: <CustomerDashboard />,
//     requiredUserType: "customer",
//   },
//   {
//     path: "/customer/plot_list",
//     element: <ShopListing />,
//     requiredUserType: "customer",
//   },
//   {
//     path: "/customer/plot_detail/:id",
//     element: <PlotGrid />,
//     requiredUserType: "customer",
//   },
//   {
//     path: "/customer/booking_management",
//     element: <BookingManagement />,
//     requiredUserType: "customer",
//   },
//   {
//     path: "/customer/book_plot",
//     element: <BookPlot />,
//     requiredUserType: "customer",
//   },
//   {
//     path: "/customer/my_bookings",
//     element: <MyBookingPlot />,
//     requiredUserType: "customer",
//   },
//   {
//     path: "/customer/documents_uploads",
//     element: <DocumentDashboard />,
//     requiredUserType: "customer",
//   },
//   {
//     path: "/customer/faqs",
//     element: <FAQSection />,
//     requiredUserType: "customer",
//   },
//   {
//     path: "/customer/my_profile",
//     element: <ProfileScreen />,
//     requiredUserType: "customer",
//   },
//   {
//     path: "/customer/payments",
//     element: <PaymentDashboard />,
//     requiredUserType: "customer",
//   },
// ];

// // Common Routes
// const commonRoutes = [
//   { path: "/login", element: <Login /> },
//   { path: "/register", element: <Register /> },
//   { path: "/employee-login", element: <EmployeeLogin /> },
//   { path: "/otp_verify/:phone", element: <OtpVerify /> },
//   { path: "/unauthorized", element: <Forbidden /> },
// ];

// const ThemeRoutes = [
//   // Common Routes
//   ...commonRoutes,

//   // Employee Routes with EmployeePrivateRoute and FullLayout
//   ...employeeRoutes.map((route) => ({
//     path: route.path,
//     element: (
//       <EmployeePrivateRoute
//         element={route.element}
//         allowedPermissions={route.allowedPermissions}
//       />
//     ),
//   })),

//   // User Routes with UserPrivateRoute and FullLayout
//   ...userRoutes.map((route) => ({
//     path: route.path,
//     element: (
//       <UserPrivateRoute
//         element={<FullLayout>{route.element}</FullLayout>}
//         requiredUserType={route.requiredUserType}
//       />
//     ),
//   })),
// ];

const ThemeRoutes = [
  { path: "/login", name: "Agent Login", exact: true, element: <Login /> },
  {
    path: "/employee-login",
    name: "Employee Login",
    exact: true,
    element: <EmployeeLogin />,
  },
  {
    path: "/otp_verify/:phone",
    name: "Agent Login",
    exact: true,
    element: <OtpVerify />,
  },
  {
    path: "/register",
    name: "Agent Register",
    exact: true,
    element: <Register />,
  },
  {
    path: "/employee",
    element: (
      <EmployeePrivateRoute
        element={<FullLayout />}
        allowedPermissions={"VIEW_DASHBOARD"}
      />
    ),
    children: [
      {
        path: "/employee/dashboard",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<SuperAdminDashboard />}
            allowedPermissions={"VIEW_DASHBOARD"}
          />
        ),
      },
      {
        path: "/employee/approval_workflow",
        name: "Employee Workflow",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<ApprovalWorkflow />}
            allowedPermissions={"VIEW_APPROVAL_WORKFLOW"}
          />
        ),
      },
      {
        path: "/employee/registeration_approval",
        name: "Employee Workflow",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<RegistrationApproval />}
            allowedPermissions={"VIEW_REGISTERATION_APPROVAL"}
          />
        ),
      },
      {
        path: "/employee/installment_approval",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<InstallmentApproval />}
            allowedPermissions={"VIEW_INSTALLMENT_APPROVAL"}
          />
        ),
      },
      {
        path: "/employee/customer_documents",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<DocumentRepo userType="customer" />}
            allowedPermissions={"VIEW_CUSTOMER_DOCS"}
          />
        ),
      },
      {
        path: "/employee/agent_documents",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<DocumentRepo userType="agent" />}
            allowedPermissions={"VIEW_AGENT_DOCS"}
          />
        ),
      },
      {
        path: "/employee/crm",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<CRM />}
            allowedPermissions={"VIEW_CRM"}
          />
        ),
      },
      {
        path: "/employee/sales_pipeline",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<SalePipeline />}
            allowedPermissions={"VIEW_SALES_PIPELINE"}
          />
        ),
      },
      {
        path: "/employee/all_customer",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<CustomerDetail userType="customer" />}
            allowedPermissions={"VIEW_ALL_CUSTOMER"}
          />
        ),
      },
      {
        path: "/employee/all_agent",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<AgentManagement userType="agent" />}
            allowedPermissions={"VIEW_ALL_AGENT"}
          />
        ),
      },
      {
        path: "/employee/all_fieldexecutive",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<FieldExecutiveManagement />}
            allowedPermissions={"VIEW_ALL_FIELDEXECUTIVE"}
          />
        ),
      },
      {
        path: "/employee/plot_management",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<PlotManagement />}
            allowedPermissions={"VIEW_PLOT_MANAGEMENT"}
          />
        ),
      },
      {
        path: "/employee/all_employee",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<EmployeeDashboard />}
            allowedPermissions={"VIEW_ALL_EMPLOYEE"}
          />
        ),
      },
      {
        path: "/employee/create_plot",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<CreatePlot />}
            allowedPermissions={"VIEW_CREATE_PLOT"}
          />
        ),
      },
      {
        path: "/employee/edit_plot/:id",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<UpdatePlot />}
            allowedPermissions={"VIEW_UPDATE_PLOT"}
          />
        ),
      },
      {
        path: "/employee/all_bookings",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<AllBookings />}
            allowedPermissions={"VIEW_ALL_BOOKINGS"}
          />
        ),
      },
      {
        path: "/employee/banners",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<PromotionBanner />}
            allowedPermissions={"VIEW_PROMOTION_BANNER"}
          />
        ),
      },
      {
        path: "/employee/payment_tracking",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<PaymentTracking />}
            allowedPermissions={"VIEW_PAYMENT_TRACKING"}
          />
        ),
      },
      {
        path: "/employee/payout",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<Payout />}
            allowedPermissions={"VIEW_PAYOUT"}
          />
        ),
      },
      {
        path: "/employee/sales_performance",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<SalePerformance />}
            allowedPermissions={"VIEW_SALES_PERFORMANCE"}
          />
        ),
      },
      {
        path: "/employee/comission_management",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<CommissionManagement />}
            allowedPermissions={"VIEW_COMISSION_MANAGEMENT"}
          />
        ),
      },
      {
        path: "/employee/plot_detail/:id",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<PlotGrid />}
            allowedPermissions={"VIEW_PLOT_DETAILS"}
          />
        ),
      },
      {
        path: "/employee/agent_wallet",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<AgentWallet />}
            allowedPermissions={"VIEW_AGENT_WALLET"}
          />
        ),
      },
      {
        path: "/employee/payment_management",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<PaymentManagement />}
            allowedPermissions={"VIEW_PAYMENT_MANAGEMENT"}
          />
        ),
      },
      {
        path: "/employee/attendance",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<Attendance />}
            allowedPermissions={"VIEW_EMPLOYEE_ATTENDANCE"}
          />
        ),
      },
      {
        path: "/employee/attendance/:employeeId/:month/:year/:day",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<Map />}
            allowedPermissions={"VIEW_ATTENDANCE_MAP"}
          />
        ),
      },
      {
        path: "/employee/attendance_catalog",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<AttendanceCatalog />}
            allowedPermissions={"VIEW_ATTENDANCE_CATALOG"}
          />
        ),
      },
      {
        path: "/employee/payroll",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<Payroll />}
            allowedPermissions={"VIEW_EMPLOYEE_PAYROLL"}
          />
        ),
      },
      {
        path: "/employee/chats",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<ChatApp />}
            allowedPermissions={"VIEW_EMPLOYEE_CHAT"}
          />
        ),
      },
      {
        path: "/employee/coupons",
        name: "Manage Promotion",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<Coupons />}
            allowedPermissions={"VIEW_ALL_COUPONS"}
          />
        ),
      },
      {
        path: "/employee/promotion_banner",
        name: "Manage Promotion",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<PromotionBanner />}
            allowedPermissions={"VIEW_ALL_BANNERS"}
          />
        ),
      },
      {
        path: "/employee/reports/individual_reports",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<IndividualReports />}
            allowedPermissions={"VIEW_INDIVIDUAL_REPORTS"}
          />
        ),
      },
      {
        path: "/employee/reports/booking_reports",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<BookingReport />}
            allowedPermissions={"VIEW_BOOKING_REPORTS"}
          />
        ),
      },
      {
        path: "/employee/reports/approval_reports",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<ApprovalReport />}
            allowedPermissions={"VIEW_APPROVAL_REPORTS"}
          />
        ),
      },
      {
        path: "/employee/reports/cancelled_reports",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<CancelledReport />}
            allowedPermissions={"VIEW_CANCELLED_REPORTS"}
          />
        ),
      },
      {
        path: "/employee/reports/agent_performance_reports",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<AgentPerformance />}
            allowedPermissions={"VIEW_AGENT_PERFORMANCE_REPORTS"}
          />
        ),
      },
      {
        path: "/employee/reports/comission_reports",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<CommissionReport />}
            allowedPermissions={"VIEW_COMISSION_REPORTS"}
          />
        ),
      },
      {
        path: "/employee/reports/document_status_reports",
        name: "Employee Dashboard",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<DocumentStatus />}
            allowedPermissions={"VIEW_DOCUMENTS_REPORT"}
          />
        ),
      },
      {
        path: "/employee/my_profile",
        name: "Employee Profile",
        exact: true,
        element: (
          <EmployeePrivateRoute
            element={<ProfileScreen />}
            allowedPermissions={"VIEW_PROFILE"}
          />
        ),
      },
    ],
  },
  {
    path: "/agent",
    element: (
      <UserPrivateRoute element={<FullLayout />} requiredUserType={"agent"} />
    ),
    children: [
      {
        path: "/agent/dashboard",
        name: "Agent Dashboard",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<Dashboard />}
            requiredUserType={"agent"}
          />
        ),
      },
      {
        path: "/agent/plot_list",
        name: "Agent Plot List",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<ShopListing />}
            requiredUserType={"agent"}
          />
        ),
      },
      {
        path: "/agent/plot_detail/:id",
        name: "Agent Plot Details",
        exact: true,
        element: (
          <UserPrivateRoute element={<PlotGrid />} requiredUserType={"agent"} />
        ),
      },
      {
        path: "/agent/booking_management",
        name: "Agent Booking Mangement",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<BookingManagement />}
            requiredUserType={"agent"}
          />
        ),
      },
      {
        path: "/agent/book_plot",
        name: "Agent Book Plot",
        exact: true,
        element: (
          <UserPrivateRoute element={<BookPlot />} requiredUserType={"agent"} />
        ),
      },
      {
        path: "/agent/agent_comission",
        name: "Agent Comission",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<Commission />}
            requiredUserType={"agent"}
          />
        ),
      },
      {
        path: "/agent/task_management",
        name: "Agent Task Management",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<TaskManagement />}
            requiredUserType={"agent"}
          />
        ),
      },
      {
        path: "/agent/customer_management",
        name: "Agent Customer Management",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<CustomerManagement />}
            requiredUserType={"agent"}
          />
        ),
      },
      {
        path: "/agent/lead_management",
        name: "Agent Lead Management",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<LeadManagement />}
            requiredUserType={"agent"}
          />
        ),
      },
      {
        path: "/agent/lead_details/:id",
        name: "Agent Lead Details",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<LeadDetails />}
            requiredUserType={"agent"}
          />
        ),
      },
      {
        path: "/agent/documents_upload",
        name: "Agent Documents Upload",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<DocumentDashboard />}
            requiredUserType={"agent"}
          />
        ),
      },
      {
        path: "/agent/faqs",
        name: "Agent Faqs",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<FAQSection />}
            requiredUserType={"agent"}
          />
        ),
      },
      {
        path: "/agent/my_profile",
        name: "Agent Profile",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<ProfileScreen />}
            requiredUserType={"agent"}
          />
        ),
      },
    ],
  },
  {
    path: "/customer",
    element: (
      <UserPrivateRoute
        element={<FullLayout />}
        requiredUserType={"customer"}
      />
    ),
    children: [
      {
        path: "/customer/dashboard",
        name: "Customer Dashboard",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<CustomerDashboard />}
            requiredUserType={"customer"}
          />
        ),
      },
      {
        path: "/customer/plot_list",
        name: "Customer PlotList",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<ShopListing />}
            requiredUserType={"customer"}
          />
        ),
      },
      {
        path: "/customer/plot_detail/:id",
        name: "Customer PlotDetail",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<PlotGrid />}
            requiredUserType={"customer"}
          />
        ),
      },
      {
        path: "/customer/booking_management",
        name: "Customer Booking Management",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<BookingManagement />}
            requiredUserType={"customer"}
          />
        ),
      },
      {
        path: "/customer/book_plot",
        name: "Customer Plot Book",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<BookPlot />}
            requiredUserType={"customer"}
          />
        ),
      },
      {
        path: "/customer/my_bookings",
        name: "Customer Bookings",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<MyBookingPlot />}
            requiredUserType={"customer"}
          />
        ),
      },
      {
        path: "/customer/payments",
        name: "Customer Bookings",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<PaymentDashboard />}
            requiredUserType={"customer"}
          />
        ),
      },
      {
        path: "/customer/documents_uploads",
        name: "Customer Documents Upload",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<DocumentDashboard />}
            requiredUserType={"customer"}
          />
        ),
      },
      {
        path: "/customer/faqs",
        name: "Customer FAQS",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<FAQSection />}
            requiredUserType={"customer"}
          />
        ),
      },
      {
        path: "/customer/my_profile",
        name: "Customer Profile",
        exact: true,
        element: (
          <UserPrivateRoute
            element={<ProfileScreen />}
            requiredUserType={"customer"}
          />
        ),
      },
    ],
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    exact: true,
    element: <Forbidden />,
  },
  { path: "*", element: <Navigate to="/login" /> },
];

export default ThemeRoutes;
