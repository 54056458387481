import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem("token") || localStorage.getItem("employeeToken");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getAllBookingsThunk = createAsyncThunk(
  "booking/getAllBookingsThunk",
  async (
    { page, limit, sortingColumn, sortingOrder, search = "" },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get(
        `/api/v1/plots/bookingHistory?page=${page}&limit=${limit}&sortingColumn=${sortingColumn}&sortingOrder=${sortingOrder}&search=${search}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const superAdminBookingSlice = createSlice({
  name: "booking",
  initialState: {
    bookings: [],
    loading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBookingsThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllBookingsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.bookings = action.payload.data;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(getAllBookingsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "An error occurred";
      });
  },
});

const superAdminBookingReducer = superAdminBookingSlice.reducer;
export default superAdminBookingReducer;
