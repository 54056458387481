import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chats: [],
  activeChats: "",
};

export const ChatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    getChats: (state, action) => {
      state.chats = action.payload;
    },
    SelectChat: (state, action) => {
      state.activeChats = action.payload;
    },
    sendMsg: (state, action) => {
      if (
        state.activeChats &&
        (action.payload.from == state.activeChats ||
          action.payload.to == state.activeChats)
      ) {
        state.chats = [...state.chats, action.payload];
      }
    },
  },
});

export const { SearchChat, getChats, sendMsg, SelectChat } = ChatSlice.actions;

export default ChatSlice.reducer;
