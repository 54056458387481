import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../utils/constant";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getCrmThunk = createAsyncThunk(
  "crm/getCrmThunk",
  async (params, { rejectWithValue }) => {
    try {
      const {
        page = 1,
        limit = 10,
        sortingColumn = "createdAt",
        sortingOrder = "desc",
      } = params;
      const response = await axios.get(`/api/v1/leads/customerRelationManage`, {
        params: {
          page,
          limit,
          sortingColumn,
          sortingOrder,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const crmSlice = createSlice({
  name: "crm",
  initialState: {
    crmData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCrmThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCrmThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.crmData = action.payload.data; 
      })
      .addCase(getCrmThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

const crmReducer = crmSlice.reducer;
export default crmReducer;
